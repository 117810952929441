import FetchApiMethod from "Helpers/FetchApiMethod";
import React, { useState } from "react";
import { Form, ListGroup,Card } from "react-bootstrap";

function SearchBox(props) {
  const data = props.searchData;
  return (
    <Form.Group>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        type="text"
        placeholder="Search..."
        onChange={props.handleChangeSearch}
      />
      {!props.selectedIp && 
      <ListGroup className="p-0 h-45 overflow-scroll">
        {data ?
        data?.map((item, index) => (
            <ListGroup.Item key={index} onClick={()=>props.handleGetValue(item.firm_name?item.firm_name:item.position)} action  className="p-0 text-white bg-dark">
            {item.firm_name?item.firm_name:item.position}
          </ListGroup.Item>
        ))
        :<Card className="bg-dark text-white border-2 rounded-2 shadow p-1"> No data found</Card>}
      </ListGroup>
      }
      {props.selectedIp && <label className="text-muted">Selected Result: <span className="bg-dark text-white">{props.selectedIp}</span> </label>}
    </Form.Group>
  );
}

export default SearchBox;
