import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import { GrGallery } from "react-icons/gr";


const GoToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Add scroll event listener when component mounts
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Remove scroll event listener when component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Show the button when user scrolls down 200 pixels
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      {isVisible && (
        <div className="go-top-button rounded-circle" onClick={scrollToTop}>
          <FaArrowUp  size={22} />
        </div>
        
      )}
    </>
  );
};

export default GoToTopButton;
