import React, { useState, useEffect, useRef } from 'react';
import MessageBox from 'components/AIBoot/MessageBox';
import ChatBotIcon from "assets/img/theme/chatbot.png";
import ChatBotGif from "assets/img/theme/BotGif.gif";
import { UncontrolledTooltip } from 'reactstrap';
import { useLocation } from 'react-router-dom'; // Assuming you're using React Router for routing

const ChatIcon = () => {
  const [showGif, setShowGif] = useState(true);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [hasAutoClicked, setHasAutoClicked] = useState(false); // Track if auto-click has occurred
  const chatIconRef = useRef(null); // Create a ref for the chat icon
  const location = useLocation(); // Get the current location/route

  // List of routes where the GIF should not be shown
  const noGifRoutes = [
    "/ICAI-Registration-From",
    "/About-ICAI",
    "/Recent-Photos",
    "/Past-Events",
    "/upcoming-events",
    "/CalenderEvents",
    "/Publication-News",
    "/articals",
    "/networkingZone",
    "/Download-Resources"
  ];

  useEffect(() => {
    // Check if the current location is not in the noGifRoutes array
    if (!noGifRoutes.includes(location.pathname)) {
      const gifTimer = setTimeout(() => {
        setShowGif(false);
        if (chatIconRef.current && !hasAutoClicked) {
          chatIconRef.current.click(); // Programmatically click the chat icon
          setHasAutoClicked(true); // Set the auto-click flag to true
        }
      }, 4000); // 4 seconds

      return () => clearTimeout(gifTimer);
    } else {
      setShowGif(false); // Don't show the GIF on these pages
    }
  }, [location, hasAutoClicked]);

  const handleClick = () => {
    setShowMessageBox(!showMessageBox);
  };

  return (
    <>
      {showGif && (
        <div className='botGifSize'
          style={{
            position: 'fixed',
            top: 0,
            left: '20%',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${ChatBotGif})`,
            backgroundSize: '50%', // Adjust the value as needed (e.g., '50%', 'cover', 'contain')
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center', // Center the background image
            zIndex: 9999,
          }}
        />
      )}
      <div
        id="chat-icon"
        onClick={handleClick}
        ref={chatIconRef} // Attach the ref to the chat icon div
        className="fixed bottom-20 left-20 z-1000"
      >
        <img src={ChatBotIcon} alt="Chat Icon" className="chat-icon btn-tooltip floating" id="tooltipBot" />
        <UncontrolledTooltip delay={0} placement="right" target="tooltipBot" trigger="hover focus">
          Chat with ICAI Saathi
        </UncontrolledTooltip>
      </div>
      {showMessageBox && <MessageBox />}
    </>
  );
};

export default ChatIcon;
