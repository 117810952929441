import React, { useState, useEffect } from "react";
import PublicationNewsComponents from "components/PublicationNewsComponents/PublicationNewsComponents.js";

const UpcomingEvents = () => {
  const [events, setEvents] = useState([]);

  const getallevent = async () => {
    const apihandler =
      "https://icaigbnapi.proeffico.com/api/event/getPublicationsnewsletter?postman=Y";
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
      },
    };
    try {
        const apihandlerres = await fetch(apihandler, settings);
        const respodata = await apihandlerres.json();
        // Assuming respodata is an array of events
        console.log(respodata, "res");
        setEvents(respodata.data);
        } catch (error) {
        console.error("Error fetching events:", error);
        }
    };

    useEffect(() => {
        getallevent();
    }, []);

    console.log(events,'line no 36');
    return (
        <div>
            <PublicationNewsComponents eventsData={events} />
        </div>
    );
};

export default UpcomingEvents;