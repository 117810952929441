export  function encrytedString(str,type){

    if(type==="email" && str){
        const encryptedEmail= str?.split('@')[0]?.slice(0, 4)+"xxxx@"+str?.split('@')[1]
        return encryptedEmail
    }
    else if(type==="phone" && str){
      const encryptedPhone= str?.toString()?.slice(0, 3)+"XXXXX"+str?.toString().slice(8, 10);
        return encryptedPhone
    }
   }