import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import FetchApiMethod from 'Helpers/FetchApiMethod'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success p-2 mx-1',
    cancelButton: 'btn btn-danger p-2 mr-1'
  },
  buttonsStyling: false
})

export function swalfirefunctionality(title, text, icon, reloadFlag, id) {
  swalWithBootstrapButtons.fire(
    `${title}`,
    `${text}`,
    `${icon}`
  ).then((result) => {
    if (result.isConfirmed && reloadFlag) {
      window.open(id, "_self");
    }
  });
}


export function AlertServices(props) {
  swalWithBootstrapButtons.fire({
    title: `${props.title}`,
    text: `${props.text}`,
    icon: `${props.icon}`,
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    reverseButtons: true
  }).then(async (result) => {
    if (result.isConfirmed) {
      if (props.apiCall == "Y") {
        FetchApiMethod(props.apiHandler, "", "Delete","CMS")
        swalfirefunctionality('Deleted!', 'Your data has been deleted.', 'success')
      } else {
        swalfirefunctionality(props.type, `Your data has been ${props.type + 'ed'}.`, 'success')
      }
    } else if (
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalfirefunctionality('Cancelled', props.dismissMassage, 'error')
    }
  })
}

export function confirmationAlertServices(props) {
  swalWithBootstrapButtons.fire({
    title: `${props.title}`,
    text: `${props.text}`,
    icon: `${props.icon}`,
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    reverseButtons: true
  }).then(async (result) => {
    if (result.isConfirmed) {
     swalfirefunctionality("",props.confirmMsg,"")
    } else if (
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalfirefunctionality('Cancelled', props.dismissMassage, 'error')
    }
  })
}