import React from "react";
// import './UpcomingEvents.css';
import "../../app.css";
import ButtonLink from "views/IndexCompnents/ButtonsSection/ButtonLink";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Badge,
  Row,
  Col
} from "reactstrap";

function formatDate(dateString) {
    const date = new Date(dateString);
    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(); // Get number of days in the month
    let day = date.getDate();
    const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthAbbreviations[date.getMonth()];
    const year = date.getFullYear();
    
    // Adjust day if it's the last day of the month
    if (day === daysInMonth) {
        // day = '31';
    }

    return `${day}-${month}-${year}`;
}



const EventCard = (props) => {
    const eventData = props.eventsData.filter(event => event);
    console.log(eventData,'line no 14');

  return (
    <>
    <section className="section">
        <div className="container-fluid">
    <h3 className="px-3 display-3 allMainHeading">Upcoming Events</h3>

    <Row className="px-3 pt-4">

  
        {eventData?.map((ele,index)=>
        <Col lg="3" sm="6" className="mb-4">
            <Card key={`${index}_events`} className="card-lift--hover shadow border-0">
              <CardImg alt="Event Image" src={`https://icaigbnapi.proeffico.com/public/img/${ele.images}`} top style={{ height: '15rem' }}/>
              <CardBody className="py-3">
                  <div>
                  <Badge color="success" pill className="mr-1">{ele.days_left} days remaining</Badge>
                    <Row >
                      <Col>
                        <CardTitle className="text-bold mt-1 text-uppercase text-truncate" style={{ color: '#212125' }}>{ele.event_name}</CardTitle>
                      </Col>
                      
                    </Row>
                    <Row>
                      <Col>
                          <ButtonLink href={`/upcoming-events-details?id=${ele.id}`} className="text-center" style={{ color: '#05007E' }}>
                            Register Now
                        </ButtonLink>
                      {/* </Col>
                      <Col> */}
                        <CardText className="description text-dark font-weight-bold text-right inCardDateMargin">{formatDate(ele.start_date)}</CardText> 
                      </Col>
                    </Row>
                    
                        
                      
                  </div>
              </CardBody>
            </Card>
        </Col>
)} 
    </Row>
    </div>
    </section>
    </>
  );
};

export default EventCard;