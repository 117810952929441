import FetchApiMethod from "Helpers/FetchApiMethod";
import React, { useEffect, useState } from "react";
import { swalfirefunctionality } from "services/alertServices";
import Calender from "views/ICAIEventsCalender";
function CalenderEvents() {
  const [calenderEvents, setCalenderEvents] = useState([]);
  const getEvents = () => {
    const getEventsData = FetchApiMethod(
      "event/getEvents?postman=Y&status=Active",
      "",
      "Post"
    );
    getEventsData.then((result) => {
      try {
        if (result.status && result.message == "success") {
          setCalenderEvents(result.data);
        }
      } catch (error) {
        swalfirefunctionality("error", result.message, "error");
      }
    });
  };
  useEffect(() => {
    getEvents();
  }, []);

  return <Calender calenderEvents={calenderEvents} />;
}

export default CalenderEvents;
