import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "react-bootstrap";
import { useForm ,Controller} from "react-hook-form";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { swalfirefunctionality } from "services/alertServices";
import { nonMemberShipAddSchema } from "./validation";
import Loading from "components/LoadingComponents/Loading";
import { TextFormGroup } from "components/lib/formGroup";
import { debounce } from "Helpers/Debounce";
import { useRef } from "react";
import { useEffect } from "react";
import { encrytedString } from "Helpers/encrytedStr";
import { confirmationAlertServices } from "services/alertServices";

import {
  Card,
  CardBody,
  CardText,
  Row,
  Col,
  Container,
  Button,
} from "reactstrap";

function Non_MemberShip_Form(props) {
  // const non_memberShipFormArr = props.non_memberShipFormArr;
  // const [payment, setPayment] = useState(false);
  // const [userDataNon, setUserDataNon] = useState([]);

  const {
    handleSubmit,
    setValue,
    register,
    reset,
    control,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(nonMemberShipAddSchema),
  });

  const [memberIdForNonMember, setMemberIdForNonMember] = useState(null);
  const [isAnnual_yn, setAnnual_yn] = useState(null);
  const handlerGetMemberNo = (event) => {
    const { name, value } = event.target;
    setMemberIdForNonMember(value);
  };
  const debounceGetMemberId = debounce(handlerGetMemberNo);

  const membership_noRef = useRef(null);
  const nameRef = useRef(null);
  const organisationRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const organisation_gstinRef = useRef(null);

  useEffect(() => {
    const handleAutofill = () => {
      console.log("sdbhfsdh");
      setValue("membership_no", membership_noRef.current.value);
      setValue("name", nameRef.current.value);
      setValue("organisation", organisationRef.current.value);
      setValue("phone", phoneRef.current.value);
      setValue("email", emailRef.current.value);
      setValue("organisation_gstin", organisation_gstinRef.current.value);
    };
    let autofillInputs = [
      membership_noRef.current,
      nameRef.current,
      organisationRef.current,
      phoneRef.current,
      emailRef.current,
      organisation_gstinRef.current,
    ];

    autofillInputs.forEach((input) => {
      console.log("input");
      input.addEventListener("input", handleAutofill);
    });

    return () => {
      autofillInputs.forEach((input) => {
        input.removeEventListener("input", handleAutofill);
      });
    };
  }, [setValue]);

  const non_memberShipFormArr = [
    {
      id: 1,
      label: "Membership No.",
      name: "membership_no",
      type: "text",
      required: "*",
      defaultValue: membership_noRef,
      func1: props.toggleCheck ? "" : debounceGetMemberId,
    },
    {
      id: 2,
      label: "Name",
      name: "name",
      type: "text",
      required: "*",
      defaultValue: nameRef,
    },
    {
      id: 3,
      label: "Organisation Name",
      name: "organisation",
      type: "text",
      required: "*",
      defaultValue: organisationRef,
    },
    {
      id: 4,
      label: "Email",
      name: "email",
      type: "text",
      required: "*",
      defaultValue: emailRef,
    },
    {
      id: 5,
      label: "Contact Number",
      name: "phone",
      required: "*",
      maxlength: "10",
      minlength: "10",
      defaultValue: phoneRef,
    },
    {
      id: 6,
      label: "GST No.",
      name: "organisation_gstin",
      type: "text",
      maxlength: "15",
      minlength: "15",
      defaultValue: organisation_gstinRef,
    },
  ];
  const handleGetMemberData = () => {
    const data = {
      postman: "Y",
      membership_id: memberIdForNonMember,
    };
    const getMemberShipData = FetchApiMethod(
      "membership/getMemberRegistration",
      data,
      "POST"
    );
    getMemberShipData.then((res) => {
      try {
        if (res.status && res.message == "success") {
          props.setMemberData(res.data[0]);
          setValue("name", res.data[0].name);
          setValue("membership_no", res.data[0].membership_no);
          setValue("organisation", res.data[0].organisation_name);
          setValue("organisation_gstin", res.data[0].organisation_gstin);
          setValue("email", encrytedString(res.data[0].email, "email"));
          setValue("phone", encrytedString(res.data[0].phone, "phone"));
          swalfirefunctionality(
            `${
              res.data[0].annual_yn == "Y"
                ? "Great! You are a annual member"
                : res.data[0].annual_yn == "N"
                ? "You are not a annual member"
                : ""
            }`,
            res.data[0].annual_yn == "N" ? "" : ``,
            res.data[0].annual_yn == "N" ? "warning" : "success"
          );
          setAnnual_yn(res.data[0].annual_yn);
        } else {
          confirmationAlertServices({
            title: `${res.message}`,
            text: "Do you want to fill the user details for non-annual event registration",
            icon: "warning",
            dismissMassage: "Please check another member number)",
            confirmMsg: "Please fill the details",
          });
          props.setMemberData(null);
        }
      } catch (error) {
        swalfirefunctionality("error", `${error.message}`, "error");
      }
    });
  };

 
  useEffect(() => {
    if (memberIdForNonMember) {
      handleGetMemberData();
    } else {
      props.setMemberData(null);
      setAnnual_yn("N");
    }
  }, [memberIdForNonMember]);

const onlyNumerciAllow=(e)=>{
    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Delete' && e.key !== 'Tab') {
      e.preventDefault()
    }
  
}

  return (
    <>
      <Container
        className={`p-0 mb-3 ${props.loader == false ? "" : "d-none"}`}
      >
        <Form noValidate onSubmit={handleSubmit(props.handleAddSubmit)}>
          <Card>
            <CardBody className="p-3">
              <Container className="p-0 d-flex justify-content-end align-items-center">
                <small className="p-0 mb-0">
                  Fee to be paid for
                  {` ${
                    // props.is_Annual_yn == "Y"
                    isAnnual_yn == "Y"
                      ? "Annual Member"
                      : "Non-Annual Member"
                  }`}
                  : <em className="fa fa-inr text-success"></em>
                </small>
                <h6 className="p-0 mb-0 mx-1">
                  {/* {props.is_Annual_yn == "Y" */}
                  {isAnnual_yn == "Y"
                    ? props.memberFee
                    : props.nonMemberFee}
                </h6>
              </Container>

              <h1 className="display-3 allMainHeading">
                {`Event Registration for  ${
                  // props.is_Annual_yn == "Y"
                  isAnnual_yn == "Y"
                    ? "Annual Member"
                    : "Non-Annual Member"
                }`}
              </h1>
              <Container className="p-0 ">
                <Row>
                  {non_memberShipFormArr?.map(
                    (elem, index) =>
                      
                        <Col md={6} key={index}>
                          <Form.Group controlId={`controlID_${elem.label}`}>
                            <Form.Label>
                              {elem.label}
                              {elem.required && (
                                <span style={{ color: "red" }}> *</span>
                              )}
                            </Form.Label>
                            <Controller
                              name={elem.name}
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <Form.Control
                                  type="text"
                                  {...field}
                                  isInvalid={!!errors[elem.name]}
                                  ref={elem.defaultValue}
                                  onChange={elem.func1?elem.func1:undefined}
                                  maxLength={elem.maxlength}
                                  minLength={elem.minlength}
                                  placeholder={elem.label + "..."}
                                  onKeyDown={elem.name=="phone"||elem.name=="membership_no"? (e)=>{onlyNumerciAllow(e)}:undefined
                                }
                                />
                              )}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors[elem.name]?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      
                  )}
                </Row>
              </Container>

              {props.paymentStats == false ? (
                <Container className="p-0 mt-2">
                  <Row>
                    <Col className="text-right">
                      <Button
                        variant="primary"
                        className="btn-md"
                        type="submit"
                      >
                        {props.is_Annual_yn == "Y"
                          ? "Submit"
                          : "Pay & Register"}
                      </Button>
                    </Col>
                  </Row>
                </Container>
              ) : (
                ""
              )}
              {props.paymentStats == true && (props.nonMemberFee!=0 || props.memberFee!=0 )? (
                <Container className="p-0 mt-2">
                  <Row>
                    <Col className="text-right">
                      <Button
                        variant="primary"
                        className="mx-3"
                        type="button"
                        onClick={props.handlepayment}
                      >
                        Pay
                      </Button>
                    </Col>
                  </Row>
                </Container>
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Form>
      </Container>
      <Loading loader={props.loader} />
    </>
  );
}

export default Non_MemberShip_Form;
