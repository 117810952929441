import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Row } from 'reactstrap';
const CustomSlider = ({ settings, children }) => {
  const childrenArray = React.Children.toArray(children);

  // const slidesToShow = childrenArray.length > 2 ? 3 : childrenArray.length;

  // if (childrenArray.length === 1) {
  //   return childrenArray[0];
  // }

  // return (
  //   <Slider {...settings} slidesToShow={slidesToShow}>
  //     {children}
  //   </Slider>
  // );

  if (childrenArray.length <= 3) {
    // If there are 3 or fewer slides, render them without the slider
    return <Row className='justify-content-start'>{children}</Row>;
  }

  // If there are more than 3 slides, render the Slider component
  return <Slider {...settings}>{children}</Slider>;
};

export default CustomSlider;



// import React from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// const CustomSlider = ({ settings, children }) => {
//   return (
//     <Slider {...settings}>
//       {children}
//     </Slider>
//   );
// };

// export default CustomSlider;