import React, { useState, useEffect } from "react";
import PastChairComponents from "components/CardComponents/PastChairComponents.js";
import PastBannersComponents from "components/CardComponents/PastBannersComponents.js";
import { swalfirefunctionality } from "services/alertServices";
import {Container,Row,Col} from "react-bootstrap";
import ResponsivePagination from "react-responsive-pagination";
import FetchApiMethod from "Helpers/FetchApiMethod";


const PastChairPersons = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [enteries, setEnteries] = useState(null);
  const [banners, setBanners] = useState([]);
  const [photo, setPhoto] = useState([]);

  const getallpastchair = async () => {
    let paginationData = {
      pagination: {
        pagesize: perPage,
        pagenumber: currentPage,
      },
    };
    // const getChairmanOfficeBearersResult=FetchApiMethod("getChairmanOfficeBearersnew?postman=Y&display_y=N","paginationData","POST");
    const getChairmanOfficeBearersResult=FetchApiMethod("getChairmanOfficeBearersnew?postman=Y&display_y=N","","POST");
    getChairmanOfficeBearersResult.then((res)=>{
      try {
        if(res.status && res.message=="success"){
          setPhoto(res.data);
          setTotalPages(res.last_page);
          setCurrentPage(res.current_page);
          setPerPage(res.per_page);
          setEnteries(res.line);
        }else{
          swalfirefunctionality("error", res.message, "error");
        }
      } catch (error) {
        swalfirefunctionality("error", error, "error");
        
      }
    })
  };

  const fetchBanners = async () => {
    try {
      const apiUrl =
        "https://icaigbnapi.proeffico.com/api/getBannerswebsite?postman=Y&banner_type=Pastchairman";
      const settings = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
        },
      };
      const response = await fetch(apiUrl, settings);
      const data = await response.json();
      setBanners(data.data || []);
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
  };

  useEffect(()=>{
    fetchBanners();
  },[]);
  useEffect(() => {
    getallpastchair();
  }, [currentPage, perPage]);

  console.log(photo, "line no 36");
  return (
    <div>
      <PastBannersComponents banners={banners} />
      <PastChairComponents pastChairData={photo} />
      <Container fluid className="p-3">
        {/* <Row>
          <Col>
            <p className="text-muted font-weight-bold">{enteries}</p>
          </Col>
          <Col>
            <ResponsivePagination
              previousLabel="Prev"
              nextLabel="Next"
              extraClassName="justify-content-end align-items-center paginationClass"
              current={currentPage}
              total={totalPages}
              onPageChange={setCurrentPage}
            />
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};

export default PastChairPersons;
