// WhatsAppIcon.js
import React from 'react';
import whatsapp from  "assets/img/theme/whatsapp-icon.png"
import { UncontrolledTooltip } from 'reactstrap';
const WhatsAppIcon = () => {
  const handleClick = () => {
    window.open('https://wa.me/919289791254', '_blank');
  };

  return (
    <div className="whatsapp-icon" style={{ position: 'fixed', zIndex: 1000 }} onClick={handleClick}>
      <img src={whatsapp} alt="WhatsApp" className="btn-tooltip floating" id="tooltipWhtsapp"/>
      <UncontrolledTooltip delay={0} placement="left" target="tooltipWhtsapp" trigger="hover focus" >
          Connect on Whatsapp</UncontrolledTooltip>
    </div>

  );
};

export default WhatsAppIcon;
