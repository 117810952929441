import { Card, CardImg } from 'reactstrap';

function ProfileCard({ alt, src, title, description, children }) {
  return (
    <Card className="bg-default shadow border-0">
      <CardImg alt={alt} src={src} top style={{height: "21rem"}}/>
      <blockquote className="card-blockquote mb-0 p-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="svg-bg"
          preserveAspectRatio="none"
          viewBox="0 0 583 95"
        >
          <polygon className="fill-default" points="0,52 583,95 0,95" />
          <polygon
            className="fill-default"
            opacity=".2"
            points="0,42 583,95 683,0 0,95"
          />
        </svg>
        <p className="lead text-italic text-white m-0" style={{ lineHeight: '1.4rem', fontSize: '16px' }}>{description}</p>
        <p className="lead text-italic text-white m-0" style={{ lineHeight: '0.8rem', fontSize: '16px' }}>{title}</p>
        {/* <h5 className="text-white">{title}</h5>  */}
      </blockquote>
      {children}  
    </Card>
  );
}

export default ProfileCard;
