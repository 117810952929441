import * as Yup from "yup";

export const memberIdSchema = Yup.object().shape({
  membership_id: Yup.string().required("Membership id is required!"),
});
export const memberShipAddSchema = Yup.object().shape({
  membership_no: Yup.string().required("Membership number is required!"),
  name: Yup.string().required("Name is required!"),
});
export const nonMemberShipAddSchema = Yup.object().shape({
  membership_no: Yup.string().required("Membership number is required!"),
  name: Yup.string().required("Name is required!"),
  organisation: Yup.string().required("Organisation name is required!"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  phone: Yup.string().required("Phone is required"),
});

