import React, { useState,useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Popover from "react-bootstrap/Popover";
import {OverlayTrigger,Button,Container,Card,Row,Col} from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomToolbar from "./customToolbar";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { swalfirefunctionality } from "services/alertServices";
import { imageURL } from "components/URLs/URL";
const localizer = momentLocalizer(moment);
const Calender = (props) => {
  const [handleAction,setHandleAction]=useState(null)
  const views = ["month"];
  const [events,setCalenderEvents] = useState([]);
  const handleClick = (action) => {
    setHandleAction(action)  
  };
  useEffect(() => {
    const currentDate=new Date();
    handleNavigate(currentDate)
  }, []);
  
 const handleNavigate = (date ) => {
    const month = (date.getMonth()+1).toString();
    const year = date.getFullYear();
    const newMonth=month.length!="2"?+"0"+month:month;
   const reqData={
    "monthyear":year+"-"+newMonth,
  }
  const getEventsData = FetchApiMethod(
    `calendargetEventswebsite`,
    reqData,
    "Post"
  );
  getEventsData.then((result) => {
    try {
      if (result.status && result.message == "success") {
        setCalenderEvents(result.data);
      }
    } catch (error) {
      swalfirefunctionality("error", result.message, "error");
    }
  });

  }


  return (
    <>
      <h1 className="px-3 display-3 mb-0 allMainHeading">GBN-Branch Events calendar</h1>
      <Container fluid className="p-2">
        <Row>
          <Col className="mb-3 border border-2 rounded-2 shadow">
            <Calendar className="calenderClass"
              localizer={localizer}
              views={views}
              events={events??[]}
              startAccessor="start_date"
              endAccessor="end_date"
              onNavigate={handleNavigate}
              onSelectEvent={(event) => console.log(event)}
              components={{
                toolbar: (props) => <CustomToolbar {...props} onPrevClick={handleClick} onTodayClick={handleClick} />,
                eventWrapper: ({ event }) => (
                  <OverlayTrigger
                    trigger="click"
                    placement="top"
                    rootClose={true}
                    overlay={
                      <Popover id={`popover-basic${event}`}>
                        <Link to={`/upcoming-events-details?id=${event.id}`}>
                        <Popover.Body className="d-flex justify-content-between p-2 align-items-center">
                          <Card.Img
                            style={{ width: "2.3rem",height:"40px" }}
                            src={`${imageURL}public/img/${event.images}`}
                          />
                          <h6 className="mx-1">
                            {event?.event_name}
                          </h6>
                        </Popover.Body>
                           </Link>
                      </Popover>
                    }
                  >
                    <Button className="eventSpan">
                      <span className="text-break">{event?.event_name}</span>
                      </Button>
                  </OverlayTrigger>
                ),
              }}
            />
          </Col>
       <Col md={3} className="mr-3">
            {events?.length != 0 ? (
              <div className="justify-content-center align-items-center ">
                <table className="table table-sm border border-2 rounded-2 shadow eventCalenderTable">
                  <thead>
                  <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Event Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {events?.map((elem, index) => (
                      <tr key={`eventTable${index}`}>
                        <td>{new Date(elem.start_date).toDateString()}</td>
                        <td>{elem.event_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Calender;
