import React from "react";

function ButtonIcon({ href, className, children, id }){
    const combinedClassName = `btn-icon-only rounded-circle ml-1 btn ${className}`;
    return(
        <>
        <a href={href} className={combinedClassName} id={id}>
        {children}   </a>
        </>
    );
}
export default ButtonIcon;