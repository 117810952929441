import React from "react";
import { Col, Row,Card, CardText, Badge } from "reactstrap";



const StudyCircle = (props) => {
const studyData= props.studyData;
    return (
        <div>
          {studyData?.map((ele,index)=>
            <Card className="bg-white p-2 rounded shadow-sm mb-3 d-flex justify-content-start " key={`${index}_card`}> 
                <Row className="p-1">
                   
                    <Col lg="8" sm="">
                    <CardText className="description font-weight-bold text-left">
                    {ele.address.split('\n').map((address, index) => (
                                  <React.Fragment key={index}>
                                    {address}
                                    <br /> {/* Render a line break after each part */}
                                  </React.Fragment>
                                ))}
                    </CardText>
                    </Col>
                    <Col lg="4" sm=""  className="badgeLeft badgeRight" >
                        <Badge color="success" pill className="">STUDY GROUP 1</Badge> 
                    </Col>
                </Row>
                <div className="table-responsive">
                    <table className="table table-bordered border-dark">
                        <thead className="hidden" hidden>
                        <tr>
                            <th className="hidden" hidden></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th className="singleLine">
                            <i className="fa fa-user" aria-hidden="true"></i>
                            &nbsp;{ele.contact_name}
                            </th>
                            <td className="singleLine h6">
                            <i className="fa fa-phone-square" aria-hidden="true"/> {ele.contact_no}
                            </td>
                            <td className="singleLine h6">
                            <i className="fa fa-envelope" aria-hidden="true"/> {ele.email}
                            </td>
                        </tr>
                        <tr>
                            <th className="singleLine ">
                            <i className="fa fa-user" aria-hidden="true"/> {ele.contact_name1}
                            </th>
                            {/* <td ></td> */}
                            <td className="singleLine h6">
                            <i className="fa fa-phone-square" aria-hidden="true"/> {ele.contact_no1}
                            </td>
                            <td className="singleLine h6">
                            <i className="fa fa-envelope" aria-hidden="true"/> {ele.email1}
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    </div>
            </Card>
            )}
        </div>
    )
}
export default StudyCircle;