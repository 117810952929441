import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Card, CardBody } from 'reactstrap';
import CounterCard from "views/IndexCompnents/CardsSection/ColorCards";
import { Link } from 'react-router-dom';
const NoticeTab = ({ Notification, counterCard  }) => {
  // console.log(Notification, 'Notification line 19');
  // console.log(counterCard , 'Notification line 6');
  const [iconTabs, setIconTabs] = useState(1);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    if (index === 4) {
      window.location.href = "/landing-page";
    } else {
      setIconTabs(index);
    }
  };

  return (
    <>
      <div className='container-fluid px-0 py-3 bg-light'>
            <h3 className="pl-6 display-3  mb-0 allMainHeading">Notice Board</h3>
     
      <Row className="justify-content-center p-3 align-items-center bg-light">
    
        <Col lg="8">
         
          <div className="nav-wrapper  p-0 pb-2">
            <Nav
              className="nav-fill flex-column flex-md-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={iconTabs === 1}
                  className={`mb-sm-3 mb-md-0 ${iconTabs === 1 ? 'active' : ''}`}
                  onClick={(e) => toggleNavs(e, 1)}
                  href="#"
                  role="tab"
                >
                  Members
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={iconTabs === 2}
                  className={`mb-sm-3 mb-md-0 ${iconTabs === 2 ? 'active' : ''}`}
                  onClick={(e) => toggleNavs(e, 2)}
                  href="#"
                  role="tab"
                >
                  Students
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={iconTabs === 3}
                  className={`mb-sm-3 mb-md-0 ${iconTabs === 3 ? 'active' : ''}`}
                  onClick={(e) => toggleNavs(e, 3)}
                  href="#"
                  role="tab"
                >
                  Other
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <Card className="shadow">
            <CardBody  style={{minHeight:330, maxHeight:330, overflowY: 'auto' }}>
              <TabContent activeTab={`iconTabs${iconTabs}`} >
                <TabPane tabId="iconTabs1">
                    {/* Render content for Members tab */}
                    {Notification && Notification.map((ele, index) => {
                      if (ele.notice_board_type === "Member") {
                        const href = ele.default_url === 'N' ? `/Notice-Details?id=${ele.id}` : ele.default_url;
                        const targetAttr = ele.default_url === 'N' ? '_self' : '_blank'; // Open in _self if id is passed, else open in _blank

                        return (
                          <div key={index}>
                             <Link to={href} target={targetAttr}>
                              <CounterCard bgColor="light" className="mb-3">
                                <Col className="p-2 d-flex">
                                  <i className="ni ni-planet mr-2" />
                                  <p className="m-0 h6 text-dark">{ele.title}</p>
                                </Col>
                              </CounterCard>
                            </Link>
                          </div>
                        );
                      }
                    })}
                  </TabPane>
                <TabPane tabId="iconTabs2">
                  {/* Render content for Students tab */}
                  {Notification && Notification.map((ele, index) => {
                    if (ele.notice_board_type === "Student") {
                      const href = ele.default_url === 'N' ? `/Notice-Details?id=${ele.id}` : ele.default_url;
                      const targetAttr = ele.default_url === 'N' ? '_self' : '_blank'; // Open in _self if id is passed, else open in _blank
                      return (
                        <div key={index}>
                           <Link to={href} target={targetAttr}>
                          <CounterCard bgColor="light" className="mb-3">
                            <Col className="p-2 d-flex">
                              <i className="ni ni-planet mr-2" />
                              <p className="m-0 h6 text-dark">{ele.title}</p>
                            </Col>
                          </CounterCard>
                          </Link>
                        </div>
                      );
                    }
                  })}
                </TabPane>
                <TabPane tabId="iconTabs3">
                  {/* Render content for Other tab */}
                  {Notification && Notification.map((ele, index) => {
                    if (ele.notice_board_type === "Other") {
                      const href = ele.default_url === 'N' ? `/Notice-Details?id=${ele.id}` : ele.default_url;
                      const targetAttr = ele.default_url === 'N' ? '_self' : '_blank'; // Open in _self if id is passed, else open in _blank
                      return (
                        <div key={index}>
                          <Link to={href} target={targetAttr}>
                          <CounterCard bgColor="light" className="mb-3">
                            <Col className="p-2 d-flex">
                              <i className="ni ni-planet mr-2" />
                              <p className="m-0 h6 text-dark">{ele.title}</p>
                            </Col>
                          </CounterCard>
                          </Link>
                        </div>
                      );
                    }
                  })}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
   
        <Col lg="3">
            <Row className="stats px-2 pt-2 align-items-center justify-content-center">
                    {/* <Col>
                    <Row className="card card-counter bg-primary">
                        <Col>
                            <i className="fa fa-code-fork" />
                        </Col>
                        <Col> 
                            <p className="count-numbers">12</p>
                            <p className="count-name">Flowz</p>
                        </Col>
                    </Row>
                    </Col> */}
                  {counterCard && (
                      <>
                    <Col lg="10" sm="12" className="mb-2 ">
                    <CounterCard bgColor="info">
                        <div className="text-center align-items-center">
                            <Col className=" align-items-start">
                                <div className="pt-2   ">
                                    <i className="fa fa-users text-white opacity-3  fa-2x" />
                                </div>
                            </Col>
                            <Col>
                            <p className="display-3 count-numbers m-0 text-white text-lg">{counterCard.total_members} +</p>
                            <p className="display-6 count-name m-0 text-white">Members</p>
                            </Col>
                            </div>
                        </CounterCard>
                    </Col>
                    <Col lg="10" sm="12" className="mb-2" >
                        {/* <Row className="row card-zoom--hover align-items-center card-counter bg-primary opacity-8 rounded shadow mb-4"> */}
                        <CounterCard bgColor="primary">
                        <div className="text-center align-items-center ">
                            <Col className=" align-items-start">
                                <div className="pt-2   ">
                                    <i className="fa fa-graduation-cap text-white opacity-3  fa-2x" />
                                </div>
                              </Col>
                            <Col>
                            <p className="display-3 count-numbers text-white text-lg m-0">{counterCard.students} +</p>
                            <p className="display-6 count-name m-0 text-white">Students</p>
                             </Col>
                            </div>
                        </CounterCard>
                        {/* </Row> */}
                    </Col>
                 
                    <Col lg="10" sm="12" className=" mb-2">
                    <Link to="/Study-Circle">
                    {/* <a  href="/Study-Circle" > */}
                        <CounterCard bgColor="danger" className="card-lift--hover ">
                            <div className="text-center align-items-center">
                                <Col className=" align-items-start">
                                    <div className="pt-2  ">
                                        <i className="fa fa-users text-white opacity-3  fa-2x" />
                                    </div>
                                </Col>
                                <Col>
                                <p className="display-3 count-numbers m-0 text-white text-lg">{counterCard.studygroup} +</p>
                                <p className="display-6 count-name m-0 text-white">CPE Study Circle</p>
                                </Col>
                            </div>
                        </CounterCard>
                      </Link>

                    </Col>
                    </>
                       )}
                    {/* <Col lg="7" className="">
                    <CounterCard bgColor="danger">
                        <div className="align-items-center d-flex">
                            <Col className=" align-items-center">
                                <div className="pt-2 icon icon-lg ">
                                    <i className="fa fa-book text-white opacity-3 " />
                                </div>
                            </Col>
                            <Col>
                            <p className="display-3 count-numbers m-0 text-white text-lg">01</p>
                            <p className="display-6 count-name m-0 text-white">Study Group</p>
                            </Col>
                            </div>
                        </CounterCard>
                    </Col> */}
                        {/* <Row className="d-flex align-items-center justify-content-around mb-2">
                            <Col>
                                <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle ">
                                    <i className="fa fa-graduation-cap" />
                                </div>
                            </Col>
                            <Col className="display-3 w-25">10</Col>
                          <Col className="display-4 w-35">Students</Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-around mb-2">
                            <Col>
                                <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle ">
                                    <i className="fa fa-users" />
                                </div>
                            </Col>
                          <Col className="display-3 w-25">10</Col>
                          <Col className="display-4 w-35">Members</Col>
                        </Row>
                        <Row className="d-flex align-items-center justify-content-around mb-2">
                            <Col>
                                <div className="icon icon-lg icon-shape icon-shape-info shadow rounded-circle ">
                                    <i className="fa fa-book" />
                                </div>
                            </Col>
                          <Col className="display-3 w-25">01</Col>
                          <Col className="display-4 w-35">Study Group</Col>
                        </Row> */}
            </Row>
        </Col>
      </Row>
      </div>
    </>
  );
}

export default NoticeTab;