import React from "react";
import "../../app.css";
import PastChairManTeams from "assets/img/theme/PastChairManTeams.png";
import DummyImg2 from  "assets/img/theme/DummyImg2.jpg"
import { FaRegAddressCard, FaPhoneSquare, FaRegEnvelope } from "react-icons/fa";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  CardHeader
} from "reactstrap";

const ImageComponents = (props) => {
  const pastChairData = props.pastChairData.filter(photos => photos);

  return (
    <>
      <section className="section">
        <div className="container-fluid">
          <h1 className="px-3 display-3 allMainHeading">Former Chairmen / Chairpersons of GBNB of ICAI</h1>
          <Row className="px-3 pt-4">
            {pastChairData?.map((ele, index) => (
              <Col lg="3" sm="6" className="mb-4" key={`${index}_events`}>
                <Card className="card-lift--hover shadow border-0">
                    <CardHeader className="" style={{height: '6rem'}}>
                        <Row>
                          <Col lg="3" className="colPadding">
                            {ele.images ? (
                              <CardImg className="mainCardHeight" alt={DummyImg2} src={`https://icaigbnapi.proeffico.com/public/img/${ele.images}`} top style={{ height: '3.2rem' }} />
                            ) : (
                              <CardImg className="mainCardHeight" alt={DummyImg2} src={DummyImg2} top style={{ height: '3.2rem' }} />
                            )}
                          </Col>
                          <Col className="leftCard colPadding" lg="9">
                            <CardTitle className="text-bold mx-1 m-0 text-uppercase wordbreakEventName" style={{ color: '#212125' }}>
                              {ele.name}
                            </CardTitle>
                            <CardText className="mx-1 description text-muted">
                              {ele.designation}, {ele.year}
                            </CardText>
                          </Col>
                        </Row>
                      </CardHeader>
                  
                    <CardBody className="py-3" style={{height: '10rem'}}>
                        <ul className="list-group">
                            <li className="list-group-item border-0 py-0">
                            <FaRegAddressCard className="mr-2" /> {ele.address ??  'N/A'}
                            </li>
                            <li className="list-group-item border-0 py-0">
                            <FaPhoneSquare className="mr-2" /> {ele.contact_no ??  'N/A'}
                            </li>
                            <li className="list-group-item border-0 py-0">
                            <FaRegEnvelope className="mr-2" /> {ele.email ?? 'N/A'}
                            </li>
                        </ul>
                    </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
};

export default ImageComponents;