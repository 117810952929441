import React from "react";
// import './UpcomingEvents.css';
import "../../app.css";
// import ButtonLink from "views/IndexCompnents/ButtonsSection/ButtonLink";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Badge,
  Row,
  Col
} from "reactstrap";

function formatDate(dateString) {
    const date = new Date(dateString);
    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(); // Get number of days in the month
    let day = date.getDate();
    const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthAbbreviations[date.getMonth()];
    const year = date.getFullYear();
    
    // Adjust day if it's the last day of the month
    if (day === daysInMonth) {
        day = '31';
    }

    return `${day}-${month}-${year}`;
}

const EventCard = (props) => {
    const eventData = props.eventsData.filter(event => event);
    console.log(eventData,'line no 14');

  return (
    <>
    <section className="section">
        <div className="container-fluid">
    <h3 className="px-3 display-3 publicationsMainHeading">Publications & Newsletters</h3>

    <Row className="px-3">

  
        {eventData?.map((ele,index)=>
        <Col lg="3" sm="6" className="mb-4">
            <div className="blur--hover"><a href={`javascript:void(window.open('${ele.document_url}', '_blank'))`}>
                <div className="icons-container blur-item mt-5 on-screen">
            <Card key={`${index}_events`} className="card-lift--hover shadow border-0">
                <CardImg alt="Event Image" src={`https://icaigbnapi.proeffico.com/public/img/${ele.thumbnail}`} top style={{ height: '15rem' }} />
                <CardBody className="py-3">
                    <div>
                        {/* <Badge color="success" pill className="mr-1">Pub. Date: {formatDate(ele.publication_date)}</Badge> */}
                        <Row>
                            <Col>
                                <CardTitle className="font-weight-bold mt-1 text-uppercase" style={{ color: '#212125' }}>{ele.title}</CardTitle>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <ButtonLink href={`javascript:void(window.open('${ele.document_url}', '_blank'))`} className="text-center" style={{ color: '#05007E' }}>
                                    Doc. Open
                                </ButtonLink>
                            </Col>
                            <Col>
                                <CardText className="description text-muted text-right inCardDateMargin">Pub. By: {ele.publication_by}</CardText>
                            </Col>
                        </Row> */}
                    </div>
                </CardBody>
            </Card>
            </div>
            <span className="blur-hidden h5 text-success">View Document</span></a></div>
        </Col>
)} 
    </Row>
    </div>
    </section>
    </>
  );
};

export default EventCard;