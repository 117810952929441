import React, { useState, useEffect } from "react";
import './About.css'; // Assuming you have styles in this file

import FetchApiMethod from "Helpers/FetchApiMethod";
import AboutComponents from "components/AboutComponents/AboutComponents";
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaWhatsapp  } from 'react-icons/fa';
import aboutBanners from "assets/img/theme/Banner4Past.png";
import {
  Container,
  Row,
  Col,
} from 'reactstrap';

function AboutUs() {
  const [banners, setBanners] = useState([]);
  const [contactDetails, setContactDetails] = useState(null);

  const fetchBanners = async () => {
    try {
      const apiUrl = "https://icaigbnapi.proeffico.com/api/getBannerswebsite?postman=Y&banner_type=AboutUs";
      const settings = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
        },
      };
      const response = await fetch(apiUrl, settings);
      const data = await response.json();
      setBanners(data.data || []);
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
  };

  const fetchContactDetails = async () => {
    try {
      const apiUrl = "https://icaigbnapi.proeffico.com/api/membership/getAboutMaster?postman=Y";
      const settings = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
        },
      };
      const response = await fetch(apiUrl, settings);
      const data = await response.json();
      setContactDetails(data.data[0] || null);
    } catch (error) {
      console.error("Error fetching contact details:", error);
    }
  };

  useEffect(() => {
    fetchBanners();
    fetchContactDetails();
  }, []);

  return (
    <>
      <div className="aboutus_container">
        <AboutComponents banners={banners} />
      </div>

      <section className="contact-section bg-fs">
          <div className="container">
                    <h3 className="aboutHeading">About Gautam Budh Nagar Branch (CIRC)</h3>         
              <div className="row">

                  <div className="col-lg-6 col-md-12 col-sm-12 order-2 mt-2 mb-3">
                      <div className="inner-column">
                          {/* <div className="sec-title ">
                           
                          </div> */}
                          <div className="text-justify">
                              <p className="card-text">Under the supervision of the Institute of Chartered Accountants of India, the Gautam Budh Nagar Branch offers three comprehensive courses. These courses include General Management and Communication Skills, Information Technology, and an Orientation Program. By complementing theoretical education with practical training modules, our aim is to equip students with the necessary skills and ethical values to excel in their professional careers.</p>
                              <p className="card-text">Established in 1991-92, the Gautam Budh Nagar Branch has grown significantly over the years. We currently boast a membership strength of more than 2500 Chartered Accountants and have more than 10,000 students in NOIDA and the NCR region. This growth is a testament to the quality of education, training, and support we provide to our members and students.</p>
                              <p className="card-text">We are proud to be a part of your professional journey and look forward to supporting you in achieving your goals in the field of Chartered Accountancy. Join us at the Gautam Budh Nagar Branch and be a part of a vibrant community that thrives on knowledge, growth, and success.</p>
                          </div>
                      </div>
                  </div>
                  
                  <div className="col-lg-6 col-md-12 col-sm-12 mt-2">
                      <div className="contact-details">
                                <div className="text-justify">
                                    <p className="card-text">Welcome to the Gautam Budh Nagar Branch, representing over a thousand Chartered Accountants spread across Gautam Budh Nagar and the NCR region. As a constituent body of the Institute of Chartered Accountants of India, we operate under an Act of Parliament (XXXVIII of 1949) to regulate the profession of Chartered Accountancy.</p>
                                    <p className="card-text">One of our primary functions at the Gautam Budh Nagar Branch is to facilitate knowledge-sharing and professional development. We organize a range of engaging events such as talks, refresher courses, workshops, seminars, and conferences, all focused on topics of professional interest. These events are designed to help our members stay updated and informed in the fast-changing scenario of finance and business.</p>
                                    <p className="card-text">To further support our members, we publish a monthly newsletter that serves as a valuable resource. It contains information about upcoming programs, Study Circle Meetings, law updates, and highlights that benefit both our members and students. Additionally, we regularly feature well-researched articles on various subjects, including Corporate Laws, Sales Tax, and Service Tax.</p>
                                </div>
                          {/* </div> */}
                      </div>
                  </div>
              </div>
          </div>
      </section>
       

      <Row className="mb-1">
          <Col xs="12" md="6" lg="3" className="mb-2">
            <div className="card h-100 border-0">
              <div className="card-body py-2 text-center">
                <a href="https://wa.me/919289791254" target="_blank" rel="noopener noreferrer">
                  <FaWhatsapp size="2em" className="text-primary floating pb-2"/>
                </a>
                <p className="card-title">{contactDetails?.whatsapp_number}</p>
              </div>
            </div>
          </Col>

          <Col xs="12" md="6" lg="3" className="mb-2">
            <div className="card h-100 border-0">
              <div className="card-body py-2 text-center">
                <a href={`mailto:${contactDetails?.contact_email}`} target="_blank" rel="noopener noreferrer">
                  <FaEnvelope size="2em" className="text-primary floating pb-2"/>
                </a>
                <p className="card-title">{contactDetails?.contact_email}</p>
              </div>
            </div>
          </Col>
        
          <Col xs="12" md="6" lg="3" className="mb-2">
            <div className="card h-100 border-0">
              <div className="card-body py-2 text-center">
                <a href="https://maps.app.goo.gl/SU4g246YX4Fcu78f7" target="_blank" rel="noopener noreferrer">
                  <FaMapMarkerAlt size="2em" className="text-primary floating pb-2"/>
                </a>
                <p className="text- capitalize">{contactDetails?.location}</p>
              </div>
            </div>
          </Col>

          <Col xs="12" md="6" lg="3" className="mb-2">
            <div className="card border-0 h-100">
              <div className="card-body py-2 text-center">
                <a href="tel:+0120-4280419" target="_blank" rel="noopener noreferrer">
                  <FaPhone size="2em" className="text-primary floating pb-2" />
                </a>
                <p>{contactDetails?.contact_phone}</p>
              </div>
            </div>
          </Col>
        </Row>


      <>
          <div className="mb-4 px-2">
            <div className="text-center">
              <h3 className="mb-4 mainHeading">
                Take A Vital Look At Our Expertise And Excellence
              </h3>
              {/* <div className="video-container"> */}
                <iframe
                  className="video-frame rounded shadow"
                  src="https://www.youtube.com/embed/gBjbm9MJ_io"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  width="100%"
                  height="550"
                ></iframe>
              {/* </div> */}
            </div>
          </div>
      </>

    </>
  );
}

export default AboutUs;