import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Applyjobs from './ApplyJobs';
import { Badge } from 'reactstrap';

export { ModalAdd }
function ModalAdd(props) {
  return (
    <Modal show={props.show} onHide={props.onHide} size="sm">
      <Modal.Header  className='p-2'>
        <Modal.Title>Apply job for <Badge pill  text="dark">{props.selectedData?.position}</Badge> </Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-2'>
        <Applyjobs onSubmit={props.onSubmit} data={props.selectedData} onHide={props.onHide}/>
      </Modal.Body>
      {/* <Modal.Footer className='p-0'>
        <Button className='btn-sm' onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

