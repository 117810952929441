import { Card } from 'reactstrap';

function CounterCard({ bgColor,className ,children}) {
  const combinedClassName = `row card-counter bg-${bgColor} opacity-8 rounded shadow mb-0 ${className}`;
  return (
    <Card className={combinedClassName}>
      {children}
    </Card>
  );
}

export default CounterCard;
