import React from "react";
// import './UpcomingEvents.css';

import ButtonLink from "views/IndexCompnents/ButtonsSection/ButtonLink";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Badge,
  Row,
  Col
} from "reactstrap";
import CustomSlider from "components/customSliders/customSlider";

function formatDate(dateString) {
    const date = new Date(dateString);
    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(); // Get number of days in the month
    let day = date.getDate();
    const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthAbbreviations[date.getMonth()];
    const year = date.getFullYear();
    
    // Adjust day if it's the last day of the month
    if (day === daysInMonth) {
        // day = '31';
    }

    return `${day}-${month}-${year}`;
}

const EventCard = (props) => {
    // const eventData=props.eventsData;
    const eventData = props.eventsData.filter(event => event.event_status === 'Active');
    console.log(eventData,'line no 14');
    const settings = {
      dots: true,
      infinite: true,
      speed: 3000,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true, // Enable autoplay
      autoplaySpeed: 3000, // Set autoplay speed in milliseconds (e.g., 2000ms = 2 seconds)
      responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: eventData.length >= 3 ? 3 : eventData.length, // Show 3 slides if there are at least 3 data items
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: eventData.length >= 2 ? 2 : eventData.length, // Show 2 slides if there are at least 2 data items
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      }
    ]
    };
  


  return (
    // {eventData.map((ele)=>)}
    <>
    <section className="section">
        <div className="container-fluid">
        <h3 className="display-3  mb-4 text-center allMainHeading">Upcoming Events</h3>

        {/* <Row className="px-3 pt-4 justify-content-start"> */}

        <CustomSlider settings={settings}>
        {eventData && eventData.length > 0 ? (
          eventData.map((ele, index) => (
            <div key={`${index}_events`} lg="3" sm="6" className="mx-3 mb-4" style={{ width: "20rem" }}>
              <Card className="card-lift--hover shadow border-0" style={{ width: "20rem" }}>
                <CardImg alt="Event Image" src={`https://icaigbnapi.proeffico.com/public/img/${ele.images}`} top style={{ height: "15rem", width: "20rem" }} />
                <CardBody className="py-3">
                    <Badge  color="success" pill className="mr-1">{ele.days_left} days remaining</Badge>
                    <div>
                      <CardTitle className="text-bold mt-3 text-uppercase text-truncate" style={{ color: '#212125' }}>{ele.event_name}</CardTitle>
                    </div>

                    {/* <CardText className="description mt-3">{ele.description}</CardText> */}
                    {/* <CardText className="description mt-3">{ele.address}, {ele.city}, {ele.state}</CardText> */}
                    <Row>
                    <Col>

                    <ButtonLink href={`/upcoming-events-details?id=${ele.id}`} className="text-left" style={{ color: '#05007E' }}>
                        Register Now
                    </ButtonLink>


                    {/* </Col>    
                    <Col> */}
                        <CardText className="description text-dark font-weight-bold text-right inCardDateMargin">{formatDate(ele.start_date)}</CardText> 
                    </Col>    
                    </Row>
                  
                </CardBody>
              </Card>
            </div>
          ))
        ) : (
          <div lg="3" sm="6" className="mb-4">
            <h6 className="title text-info text-center mb-0">Coming Soon <span><i className="fa fa-refresh fa-spin"></i></span> </h6>
            {/* <CardTitle className="text-bold mt-3 text-uppercase" style={{ color: '#212125' }}>Coming Soon ...</CardTitle> */}
          </div>
        )}
  </CustomSlider>
    {/* </Row> */}
    </div>
    </section>
    </>
  );
};

export default EventCard;