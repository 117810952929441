import { apiURL,otpServiceV2Url } from "components/URLs/URL";
export default async function FetchApiMethod(apiEndPoint,data,method) {
  const authtokenn = "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d";
  let finaldata = "";
  if (data == "" || data == undefined) {
    finaldata = undefined;
  } else {
    finaldata = data;
  }
  let withBody = {
    method: method != undefined ? method : "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${authtokenn}`,
    },
    body: JSON.stringify(finaldata),
  };
  let withoutBody = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${authtokenn}`,
    },
  };
  const result = await fetch(
    `${apiURL}${apiEndPoint}`,
    method != "GET" ? withBody : withoutBody
  );

  return result.json();
}

async function fetchAPIThirdParty(apiEndPoint, data, method) {
  const result = await fetch(
    `${otpServiceV2Url}${apiEndPoint}`,
    {
      method: method,
      headers: {"Content-Type": "application/json",
      Authorization: "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",},
      body: JSON.stringify(data),
    }
  );

  return result.json();
}
export { fetchAPIThirdParty };

export const headers_image_upload = { 'Content-Type': 'multipart/form-data', 'Authorization': "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d" };

