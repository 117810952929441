import React from "react";

function RoundedImage({ alt, src }) {
  return (
    <img
      alt={alt}
      className="rounded-circle img-center  pt-2 p-1  img-fluid shadow shadow-lg--hover "
      src={src}
      style={{width: "180px", height:"180px" }}
    />
  );
}

export default RoundedImage;
