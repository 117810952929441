import React, { useState, useEffect } from "react";
import HeroHeader from "views/IndexCompnents/HeaderSection/HeroHeader";
import IcaiNavBar from "components/Navbars/IcaiNavBar";
import UpcomingEventsCards from "components/PastEventsComponents/UpcomingEventsComp";
import MyFooter from "components/Footers/IcaiFooter.js";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { swalfirefunctionality } from "services/alertServices";
import { Row,Col } from "react-bootstrap";
import ResponsivePagination from "react-responsive-pagination";

const UpcomingEvents = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [enteries, setEnteries] = useState(null);
  const [events, setEvents] = useState([]);

  const getallevent = async () => {
    let paginationData = {
      pagination: {
        pagesize: perPage,
        pagenumber: currentPage,
      },
    };
    const getEventsApiResult = FetchApiMethod(
      "getEventswebsite?postman=Y&status=Active",
      paginationData,
      "POST"
    );
    getEventsApiResult.then((res) => {
      try {
        if (res.status && res.message == "success") {
          console.log(res, "getevent");
          setEvents(res.data);
          setTotalPages(res.last_page);
          setCurrentPage(res.current_page);
          setPerPage(res.per_page);
          setEnteries(res.line);
        } else {
          swalfirefunctionality("error", res.message, "error");
        }
      } catch (error) {
        swalfirefunctionality("error", error, "error");
      }
    }); 
  
    };

    useEffect(() => {
        getallevent();
    }, [currentPage, perPage]);
    return (
        <div>
            <UpcomingEventsCards eventsData={events} />
            <Row>
          {/* <Col>
            <p className="text-muted font-weight-bold">{enteries}</p>
          </Col> */}
          <Col>
            <ResponsivePagination
              previousLabel="Prev"
              nextLabel="Next"
              extraClassName="justify-content-end paginationClass align-items-center"
              current={currentPage}
              total={totalPages}
              onPageChange={setCurrentPage}
            />
          </Col>
        </Row>
        </div>
    );
};

export default UpcomingEvents;