import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { memberShipAddSchema } from "./validation";
import Loading from "components/LoadingComponents/Loading";
import {
  Card,
  CardBody,
  CardText,
  Row,
  Col,
  Container,
  Button,
} from "reactstrap";

function MemberShipForm(props) {
  const memberShipFormArr=props.memberShipFormArr;
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(memberShipAddSchema),
  });

  console.log(props.loader,'loader')
  return (
    <>
      <Container className={`p-0 mb-3 ${props.loader == false ? '' : 'd-none'}`}>
    <Form noValidate onSubmit={handleSubmit(props.handleAddSubmit)}>
        <Card>
          <CardBody className="p-3">
            <Container className="p-0 d-flex justify-content-end align-items-center">
              <small className="p-0 mb-0">
                Fee to be paid for
                {` ${
                  props.is_Annual_yn == "Y"
                    ? "Annual Member"
                    : "Non-Annual Member"
                }`}{" "}
                : <em className="fa fa-inr text-success"></em>{" "}
              </small>
              <h6 className="p-0 mb-0 mx-1">
                {props.is_Annual_yn == "Y"
                  ? props.memberFee
                  : props.is_Annual_yn == "N"
                  ? props.nonMemberFee
                  : ""}
              </h6>
            </Container>
            <h1 className="display-3 allMainHeading">
              {`Event Registration for  ${props.is_Annual_yn == "N"?"Non-Annual Member":"Annual Member"}`}
            </h1>
            <Container className="p-0 ">
                <Row>
                  {memberShipFormArr?.map((elem, index) => (
                    elem.hide=="N" &&
                    <Col md={6} key={index}>
                      <elem.component
                        name={elem.name}
                        placeholder={elem.ref + "..."}
                        label={elem.ref}
                        register={register}
                        control={control}
                        errors={errors}
                        dirtyFields={dirtyFields}
                        required={elem.required}
                        row={elem.row}
                        maxlength={elem.maxlength}
                        minlength={elem.minlength}
                        defaultValue={elem.defaultValue}
                        readonly={elem.readonly}
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
              {props.paymentStats == false ? <Container className="p-0 mt-2">
           
              <Row>
                <Col className="text-right">
                  <Button variant="primary" className="btn-md" type="submit">
                    {props.is_Annual_yn == "Y"
                      ? "Submit"
                      : "Pay & Register"}
                  </Button>
                </Col>
              </Row>
            </Container>: ""}
            {props.paymentStats == true && (props.nonMemberFee!=0|| props.memberFee!=0) ? <Container className="p-0 mt-2">
              <Row>
                <Col className="text-right">
                  <Button variant="primary" className="mx-3" type="button" onClick={props.handlepayment}>Pay</Button>
                </Col>
              </Row>
            </Container> : ''}
          </CardBody>
        </Card>
    </Form>
      </Container>
       <Loading loader={props.loader} />
       </>
  );
}

export default MemberShipForm;
