import React from 'react';

import ContactForm from "components/ContactUs/ContactForm.js"

const ContactPage = () => {
  return (
    <div>
      {/* <h1>Contact Us</h1> */}
      <ContactForm />
    </div>
  );
};

export default ContactPage;