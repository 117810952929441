import React, { useEffect, useState } from "react";
import { Container, Row, Card, CardBody, CardTitle, Col, CardText, CardHeader, CardImg, Modal, ModalBody } from "reactstrap";
import { useLocation } from "react-router-dom";

const NoticeDetails = () => {
    const [notice, setNotice] = useState(null);
    const [modal, setModal] = useState(false);
    const location = useLocation();
    const url_id = new URLSearchParams(location.search).get("id");

    const toggleModal = () => setModal(!modal);

    const fetchNotice = async (url_id) => {
        const apihandler = `https://icaigbnapi.proeffico.com/api/getNoticeboardwebsite?postman=Y&id=${url_id}`;
        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
            },
        };

        try {
            const apihandlerres = await fetch(apihandler, settings);
            const respodata = await apihandlerres.json();
            setNotice(respodata.data[0]);
        } catch (error) {
            console.error("Error fetching notice detail:", error);
        }
    };

    useEffect(() => {
        fetchNotice(url_id);
    }, [url_id]);

    return (
        <section>
            <Container>
                <Row className="mt-5 justify-content-center align-items-center">
                    <Col lg="12" sm="12" className="mb-4">
                        <Card className="mx-3 shadow border-0">
                            <CardHeader>
                                <h3 className="ml-3 mb-0">{notice?.notice_board_type}'s Notice</h3>
                            </CardHeader>
                            <CardBody className="py-3" style={{ minHeight: "450px" }}>
                                <Row className="justify-content-between align-items-center">
                                    <Col lg="8" sm="8">
                                        <CardTitle className="text-bold mt-3 text-uppercase" style={{ color: '#212125' }}>
                                            {notice?.title}
                                        </CardTitle>
                                    </Col>
                                </Row>
                                <CardText className="description mt-0">{notice?.description}</CardText>
                                <Row>
                                    <Col>
                                        {notice?.image_url && (
                                            <CardImg
                                                alt="Notice Image"
                                                src={`https://icaigbnapi.proeffico.com/public/img/${notice?.image_url}`}
                                                bottom
                                                style={{ height: '15rem', cursor: 'pointer' }}
                                                onClick={toggleModal}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {notice?.image_url && (
                <Modal isOpen={modal} toggle={toggleModal} size="lg">
                    <ModalBody>
                        <img
                            src={`https://icaigbnapi.proeffico.com/public/img/${notice?.image_url}`}
                            alt="Notice Image"
                            style={{ width: '100%' }}
                        />
                    </ModalBody>
                </Modal>
            )}
        </section>
    );
}

export default NoticeDetails;
