import React from 'react';
import { Button , Container} from 'react-bootstrap';

class CustomToolbar extends React.Component {
  handlePrevClick = () => {
    console.log("Previous button clicked");
    if (this.props.onPrevClick) {
      this.props.onPrevClick("PREV");
    }
    this.props.onNavigate('PREV');
  };
  handleNextClick = () => {
    this.props.onNavigate('NEXT');
  };
  handleTodayClick = () => {
    if (this.props.onTodayClick) {
        this.props.onTodayClick("TODAY");
      }
    this.props.onNavigate('TODAY');
  };
//   handlePastClick = () => {
//     if (this.props.onPastClick) {
//         this.props.onPastClick("PAST");
//       }
//     this.props.onNavigate('PAST');
//   };

  render() {
    const { label } = this.props;
    return (
      <Container className="rbc-toolbar mt-3">
        <span className="rbc-btn-group">
          <Button className='bg-light btn-sm text-dark border-0' type="button" onClick={this.handlePrevClick}>Prev</Button>
          <Button className='bg-light btn-sm text-dark border-0' type="button" onClick={this.handleTodayClick}>Today</Button>
          <Button className='bg-light btn-sm text-dark border-0' type="button" onClick={this.handleNextClick}>Next</Button>
          {/* <Button className='bg-light btn-sm text-dark border-0' type="button" onClick={this.handlePastClick}>Past</Button> */}
        </span>
        <span className="rbc-toolbar-label">{label}</span>
      </Container>
    );
  }
}

export default CustomToolbar;
