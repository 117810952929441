import React, { useState, useEffect } from "react";
import ImageComponents from "components/ImageComponents/ImageComponents.js";

const RecentPhotos = () => {
  const [photo, setPhoto] = useState([]);

  const getallphotos = async () => {
    const apihandler =
      "https://icaigbnapi.proeffico.com/api/ICAIphotogallery?postman=Y";
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
      },
    };
    try {
        const apihandlerres = await fetch(apihandler, settings);
        const respodata = await apihandlerres.json();
        // Assuming respodata is an array of photo
        console.log(respodata, "res");
        setPhoto(respodata.data);
        } catch (error) {
        console.error("Error fetching photo:", error);
        }
    };

    useEffect(() => {
        getallphotos();
    }, []);

    console.log(photo,'line no 36');
    return (
        <div>
            <ImageComponents photosData={photo} />
        </div>
    );
};

export default RecentPhotos;