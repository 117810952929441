import React from "react";
import promo from "assets/img/theme/promo-1.png"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardText,
    Badge,
  } from "reactstrap";
  import { Link } from "react-router-dom";
//   import ButtonLink from "views/IndexCompnents/ButtonsSection/ButtonLink";
//   import CustomSlider from "components/customSliders/customSlider";
 
   function PublicationCards({publicationData}) {
//    console.log(publicationData,'publication res');

//    const settings = {
//     dots: true,
//     infinite: true,
//     speed: 2000,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: publicationData.length >= 3 ? 3 : publicationData.length, // Show 3 slides if there are at least 3 data items
//           dots: true
//         }
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: publicationData.length >= 2 ? 2 : publicationData.length, // Show 2 slides if there are at least 2 data items
//           dots: true
//         }
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           dots: true
//         }
//       }
//     ]
//   };
function formatDate(dateString) {
    const date = new Date(dateString);
    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(); // Get number of days in the month
    let day = date.getDate();
    const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthAbbreviations[date.getMonth()];
    const year = date.getFullYear();
    
    // Adjust day if it's the last day of the month
    if (day === daysInMonth) {
        day = '31';
    }

    return `${day}-${month}-${year}`;
}

    return(
        
        <>
        
        <section className="section bg-secondary pt-4 px-4">
            
            <h3 className="display-3 text-center allMainHeading pb-0">Publications & Newsletter</h3>
            <Row className="justify-content-center">
        {/* <CustomSlider settings={settings}> */}
        {publicationData?.map((ele,index)=>
            <div lg="4" md="4" sm="10" className=" p-2"  key={`${index}_lettr`}>
           {/* <div className="blur--hover"><a href={ele.document_url} target="_blank">
                <div className="icons-container blur-item mt-5 on-screen">
                                <Card className="card-lift--hover mx-2 shadow border-0"  style={{height:"400px"}}>
                                    <img className="img-fluid" alt="100%x280" src={ele.thumbnail ? `https://icaigbnapi.proeffico.com/public/img/${ele.thumbnail}` : promo}  style={{ height: '15rem',width: '370px' }} />
                                <CardBody className="text-center">
                                    <h4>{ele.title}</h4>
                                    <p>{ele.description}</p>
                                   
                                </CardBody>
                                </Card>
                </div>
            <span className="blur-hidden h5 text-success">Explore More</span></a></div> */}
                    <div className="blur--hover">
                        {/* <a href={`/PublicationNewsletter-Detail?id=${ele.id}`}> */}
                        <Link to={ele.id ? `/PublicationNewsletter-Detail?id=${ele.id}` : ''}>
                        <div className="icons-container blur-item mt-1 on-screen">
                            <Card className="card-lift--hover shadow mx-2 border-0"  >
                                    <CardImg alt="Publications & Newsletter" src={ele.thumbnail ? `https://icaigbnapi.proeffico.com/public/img/${ele.thumbnail}` : promo} className="img-fluid" style={{height: "20rem", width: "20rem"}} />
                                   
                                <CardBody className=" py-1">
                                   
                                <div>
                                            {/* <Badge color="success" pill className="mr-1">Pub. Date: {formatDate(ele.publication_date)}</Badge> */}
                                            <Row>
                                                <Col>
                                                    <CardTitle className="font-weight-bold mt-1 text-uppercase" style={{ color: '#212125' }}>{ele.title}</CardTitle>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col className="px-1">
                                                    <ButtonLink href={`javascript:void(window.open('${ele.document_url}', '_blank'))`} className="text-center" style={{ color: '#05007E' }}>
                                                        Doc. Open
                                                    </ButtonLink>
                                                </Col>
                                                <Col className="px-1">
                                                    <CardText className="description text-muted text-right">Pub. By: {ele.publication_by}</CardText>
                                                </Col>
                                            </Row> */}
                                        </div>
                                   
                                </CardBody>
                     
                            </Card>
                        </div>
                    <span className="blur-hidden h5 text-success">Explore More</span></Link></div>

            </div>
        )}
          {/* </CustomSlider> */}
            {/* <Col lg="4">
            <div className="blur--hover"><a href="#/documentation/icons">
                <div className="icons-container blur-item mt-5 on-screen">
                                <Card className="card-lift--hover shadow border-0">
                                    <img className="img-fluid" alt="100%x280" src={promo} style={{ height: '15rem' }} />
                                    <CardBody className="text-center">
                                        <h4>GBNB Newsletter</h4>
                                        <p>Aliquip placeat salvia cillum iphone.</p>
                                    
                                    </CardBody>
                                </Card>
                </div>
            <span className="blur-hidden h5 text-success">Explore More</span></a></div>
            </Col>
            <Col lg="4">
            <div className="blur--hover"><a href="#/documentation/icons">
                <div className="icons-container blur-item mt-5 on-screen">
                                <Card className="card-lift--hover shadow border-0">
                                    <img className="img-fluid" alt="100%x280" src={promo} style={{ height: '15rem' }} />
                                    <CardBody className="text-center">
                                        <h4>GBNB Newsletter</h4>
                                        <p>Aliquip placeat salvia cillum iphone.</p>
                                    
                                    </CardBody>
                                </Card>
                </div>
            <span className="blur-hidden h5 text-success">Explore More</span></a></div>
            </Col> */}
        </Row>
        
        </section>
        </>
    );
   }
   export default PublicationCards;