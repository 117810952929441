import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { Container } from 'reactstrap';
import HeroHeader from "views/IndexCompnents/HeaderSection/HeroHeader";
import IcaiNavBar from "components/Navbars/IcaiNavBar";
import MyFooter from "components/Footers/IcaiFooter.js";

function FormExample() {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({ type: '' });

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <>
    {/* <HeroHeader />
      <IcaiNavBar /> */}
    <Container>
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {/* <Row className="mb-3">
        
        
      </Row> */}
      <Row className="mb-3 mt-5 text-center">
        <Form.Group as={Col} md="12" controlId="validationCustom01">
          <Form.Label>Type &nbsp;<span style={{ color: "red" }}>*</span></Form.Label>
          <Form.Select name="type" style={{ width: "40%" }} value={formData.type} onChange={handleInputChange} required>
            <option value="">Select</option>
            <option value="member">Member</option>
            <option value="non-member">Non-Member</option>
          </Form.Select>

          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        </Row>
        <Row>
        {formData.type === 'member' && (
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>Membership Number<span style={{ color: "red" }}>*</span></Form.Label>
            <Form.Control type="text" placeholder="Membership Number" required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid membership number.
            </Form.Control.Feedback>
          </Form.Group>
        )}
        {formData.type === 'member' && (
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>Name<span style={{ color: "red" }}>*</span></Form.Label>
            <Form.Control type="text" placeholder="Membership Number" required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid membership number.
            </Form.Control.Feedback>
          </Form.Group>
        )}
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom04">
          <Form.Label>Organization<span style={{ color: "red" }}>*</span></Form.Label>
          <Form.Control type="text" placeholder="Organization" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid organization.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom05">
          <Form.Label>Email ID<span style={{ color: "red" }}>*</span></Form.Label>
          <Form.Control type="email" placeholder="Email ID" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email ID.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom06">
          <Form.Label>Contact No.<span style={{ color: "red" }}>*</span></Form.Label>
          <Form.Control type="text" placeholder="Contact No." required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid contact number.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom07"><Form.Label>GST No.<span style={{ color: "red" }}>*</span></Form.Label>
          <Form.Control type="text" placeholder="GST No." required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid GST number.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom08">
          <Form.Label>Address<span style={{ color: "red" }}>*</span></Form.Label>
          <Form.Control type="text" placeholder="Address" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid address.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="2" controlId="validationCustom09">
          <Form.Label>Country<span style={{ color: "red" }}>*</span></Form.Label>
          <Form.Control type="text" placeholder="Country" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid country.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="2" controlId="validationCustom10">
          <Form.Label>State<span style={{ color: "red" }}>*</span></Form.Label>
          <Form.Control type="text" placeholder="State" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid state.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="2" controlId="validationCustom11">
          <Form.Label>City<span style={{ color: "red" }}>*</span></Form.Label>
          <Form.Control type="text" placeholder="City" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom12">
          <Form.Label>Pincode<span style={{ color: "red" }}>*</span></Form.Label>
          <Form.Control type="text" placeholder="Pincode" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid pincode.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3">
        <Form.Check
          required
          label="Agree to terms and conditions"
          feedback="You must agree before submitting."
          feedbackType="invalid"
        />
      </Form.Group>
      <Button type="submit">Submit form</Button>
    </Form>
    <br/>
    </Container>
    {/* <MyFooter /> */}
    </>
  );
}

export default FormExample;