import React from "react";
import { Button, Card, CardBody, Row, Col } from 'reactstrap';

const OurResources = () => {
   return(
    <>
     <section className="section bg-light">
        <div className="container-fluid justify-content-center">
            <h3 className="display-3 text-center">ICAI Resources</h3>
            <Row>
            {/*  */}

                <Col lg="4"  className="mb-3">
                    <Card style={{height:"15rem"}}>
                        <CardBody className="text-center align-content-center   shadow-sm">
                        <i className="ni ni-world-2 fa-2x text-primary"/>
                        {/* <i className="fa fa-twitter" /> */}
                            <h5 className="heading">
                                Accounting Standard
                            </h5>
                            <Button><a href="https://www.icai.org/Resources.html?mod=3">View <i className="fa fa-long-arrow-right"/></a></Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4"  className="mb-3">
                    <Card style={{height:"15rem"}}>
                        <CardBody className="text-center align-content-center   shadow-sm">
                        <i className="ni ni-world-2 fa-2x text-primary"/>
                        {/* <i className="fa fa-twitter" /> */}
                            <h5 className="heading">
                            Auditing Standard
                            </h5>
                            <Button><a href="https://www.icai.org/Resources.html?mod=3">View <i className="fa fa-long-arrow-right"/></a></Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4"  className="mb-3">
                    <Card style={{height:"15rem"}}>
                        <CardBody className="text-center align-content-center   shadow-sm">
                        <i className="ni ni-world-2 fa-2x text-primary"/>
                        {/* <i className="fa fa-twitter" /> */}
                            <h5 className="heading">
                                Exposure Drafts 
                            </h5>
                            <Button><a href="https://www.icai.org/Resources.html?mod=3">View <i className="fa fa-long-arrow-right"/></a></Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4"  className="mb-3">
                    <Card style={{height:"15rem"}}>
                        <CardBody className="text-center align-content-center   shadow-sm">
                        <i className="ni ni-world-2 fa-2x text-primary"/>
                        {/* <i className="fa fa-twitter" /> */}
                            <h5 className="heading">
                                Exposure Drafts 
                            </h5>
                            <Button><a href="https://www.icai.org/Resources.html?mod=3">View <i className="fa fa-long-arrow-right"/></a></Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4"  className="mb-3">
                    <Card style={{height:"15rem"}}>
                        <CardBody className="text-center align-content-center   shadow-sm">
                        <i className="ni ni-world-2 fa-2x text-primary"/>
                        {/* <i className="fa fa-twitter" /> */}
                            <h5 className="heading">
                            Guidance Notes  
                            </h5>
                            <Button><a href="https://www.icai.org/Resources.html?mod=3">View <i className="fa fa-long-arrow-right"/></a></Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4"  className="mb-3">
                    <Card style={{height:"15rem"}}>
                        <CardBody className="text-center align-content-center   shadow-sm">
                        <i className="ni ni-world-2 fa-2x text-primary"/>
                        {/* <i className="fa fa-twitter" /> */}
                            <h5 className="heading">
                            Valuation Standard  
                            </h5>
                            <Button><a href="https://www.icai.org/Resources.html?mod=3">View <i className="fa fa-long-arrow-right"/></a></Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4"  className="mb-3">
                    <Card style={{height:"15rem"}}>
                        <CardBody className="text-center align-content-center   shadow-sm">
                        <i className="ni ni-world-2 fa-2x text-primary"/>
                        {/* <i className="fa fa-twitter" /> */}
                            <h5 className="heading">
                            Standards on Internal Audit  
                            </h5>
                            <Button><a href="https://www.icai.org/Resources.html?mod=3">View <i className="fa fa-long-arrow-right"/></a></Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4"  className="mb-3">
                    <Card style={{height:"15rem"}}>
                        <CardBody className="text-center align-content-center   shadow-sm">
                        <i className="ni ni-world-2 fa-2x text-primary"/>
                        {/* <i className="fa fa-twitter" /> */}
                            <h5 className="heading">
                            Engagement and Quality Control Standard  
                            </h5>
                            <Button><a href="https://www.icai.org/Resources.html?mod=3">View <i className="fa fa-long-arrow-right"/></a></Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4"  className="mb-3">
                    <Card style={{height:"15rem"}}>
                        <CardBody className="text-center align-content-center   shadow-sm">
                        <i className="ni ni-world-2 fa-2x text-primary"/>
                        {/* <i className="fa fa-twitter" /> */}
                            <h5 className="heading">
                            Analysis of Modified Opinions 
                            </h5>
                            <Button><a href="https://resource.cdn.icai.org/75152caq60725amo.pdf">View <i className="fa fa-long-arrow-right"/></a></Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4"  className="mb-3">
                    <Card style={{height:"15rem"}}>
                        <CardBody className="text-center align-content-center   shadow-sm">
                        <i className="ni ni-world-2 fa-2x text-primary"/>
                        {/* <i className="fa fa-twitter" /> */}
                            <h5 className="heading">
                            Firm Manual Model Referencer 
                            </h5>
                            <Button><a href="https://resource.cdn.icai.org/75151caq60725fmmr.pdf">View <i className="fa fa-long-arrow-right"/></a></Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4"  className="mb-3">
                    <Card style={{height:"15rem"}}>
                        <CardBody className="text-center align-content-center   shadow-sm">
                        <i className="ni ni-world-2 fa-2x text-primary"/>
                        {/* <i className="fa fa-twitter" /> */}
                            <h5 className="heading">
                            Download Utility for Materiality 
                            </h5>
                            <Button><a href="https://docs.google.com/forms/d/e/1FAIpQLSduJ9FHpl3Kkx5HHF65N3jGiYP2US_JyMw6nNE7D2K5NtOvRw/viewform">View <i className="fa fa-long-arrow-right"/></a></Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4"  className="mb-3">
                    <Card style={{height:"15rem"}}>
                        <CardBody className="text-center align-content-center   shadow-sm">
                        <i className="ni ni-world-2 fa-2x text-primary"/>
                        {/* <i className="fa fa-twitter" /> */}
                            <h5 className="heading">
                            Download Utility on Engagement Letter
                            </h5>
                            <Button><a href="https://docs.google.com/forms/d/e/1FAIpQLSfU8M6DfoLKm70fd2Pz9ktdqGkya1M_EKb5M7j-DFaRQ4uPUA/viewform">View <i className="fa fa-long-arrow-right"/></a></Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4"  className="mb-3">
                    <Card style={{height:"15rem"}}>
                        <CardBody className="text-center align-content-center   shadow-sm">
                        <i className="ni ni-world-2 fa-2x text-primary"/>
                        {/* <i className="fa fa-twitter" /> */}
                            <h5 className="heading">
                            Compilation of Significant Audit Matters
                            </h5>
                            <Button><a href="https://resource.cdn.icai.org/73000caq58829.pdf">View <i className="fa fa-long-arrow-right"/></a></Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
        </section>
    </>
   );
}

export default OurResources;