import React, { useState, useEffect, useRef } from 'react';
import './Bot.css';
import Spinner from 'react-bootstrap/Spinner';
import LogoIcon from "assets/img/theme/ICAI-Logo.png";
import Userss from "assets/img/theme/Users.png";
import { TbNavigation } from "react-icons/tb";
import { IoPowerOutline, IoExpandOutline, IoContractOutline } from "react-icons/io5";
import { FaCopy } from "react-icons/fa";
import { TbCopyCheck } from "react-icons/tb";

const BotMessage = ({ message, time, isBot }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(message);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

    const formatMessage = (message) => {
    // Replace "**" with <b> tags
    message = message.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
    
    // Replace "\t+" with &nbsp;&nbsp;
    message = message.replace(/\t\+/g, '&nbsp;&nbsp;');
    
    // Replace "\n*" with <ul><li> and closing tags
    message = message.split('\n*').map((part, index) => {
      if (index === 0) {
        return part;
      } else {
        return `<ul><li>${part}</li></ul>`;
      }
    }).join('');

    // Replace "\n" with <br> tags
    message = message.replace(/\n/g, '<br>');

    return message;
  };

  return (
    <div className={`d-flex flex-row justify-content-${isBot ? 'start' : 'end'} mb-4`}>
      {isBot && (
        <img src={LogoIcon} alt="Bot Avatar" style={{ width: '30px', height: '50%' }} />
      )}
      {isBot && (
        <i className="fa fa-caret-left color-blue-900" />
      )}
      <div className="d-flex flex-column messageBotColour">
        <div className={`small p-2 ${isBot ? 'ms-3 text-white rounded p-2 bg-blue-900' : 'me-3 bg-white-500 rounded p-2'}`}>
          <div dangerouslySetInnerHTML={{ __html: formatMessage(message) }} />
          {isBot && (
            <button
              className={`btn btn-sm border-0 mx-2 ${copied ? 'border-0' : ''}`}
              onClick={copyToClipboard}
            >
              {copied ? <TbCopyCheck className='text-success' /> : <FaCopy className='text-primary' />}
            </button>
          )}
        </div>
        <span className="small text-muted">{time}</span>
      </div>
      {!isBot && <i className="fa fa-caret-right" />}
      {!isBot && (
        <img src={Userss} alt="User Avatar" style={{ width: '30px', height: '50%' }} />
      )}
    </div>
  );
};

const MessageBox = () => {
  const [question, setQuestion] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const messageBoxRef = useRef();
  const scrollableDivRef = useRef(null);
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (messageBoxRef.current && !messageBoxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (question.trim() !== '') {
      setMessages((prevMessages) => [...prevMessages, { message: question, time: new Date().toLocaleTimeString(), isBot: false }]);
      setIsLoading(true);
      setIsInputDisabled(true);

      try {
        setQuestion('');
        const result = await fetch("https://otp.proeffico.com/api/v2.0/genericAPIBOT", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer $2yhwbbdwhdwudjceii5Tc0eTBK.WHfuUUwfVcnjfDBBDUjcdwcnZh281qAIkaw"
          },
          body: JSON.stringify({
            msg: "You are the virtual assistant for the Institute of Chartered Accountants of India (ICAI), a prestigious professional body dedicated to regulating and promoting the accountancy profession in India. Your primary goal is to assist members and the public in understanding and adhering to the Code of Ethics issued by ICAI. You are equipped with extensive knowledge about the Code of Ethics and related regulations, and your responses should always align with ICAI's ethical standards. Remember to prioritize accuracy, integrity, and professionalism in providing assistance to anyone seeking guidance on ethical matters related to accounting and auditing practices",
            req_query: question
          })
        });

        const data = await result.json();
        setMessages((prevMessages) => [...prevMessages, { message: data.data, time: new Date().toLocaleTimeString(), isBot: true }]);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
        setIsInputDisabled(false);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <>
      {isOpen && (
        <div id="message-box" ref={messageBoxRef} className={`fixed z-9999 mx-auto p-4 ${isFullScreen ? 'fullscreen' : 'centered'}`}>
          <div className="bg-green-700 rounded p-3">
            <header className="flex justify-between items-center pb-2 border-bottom">
                <h6 className="text-xs font-bold text-white mb-0">ICAI GBN Saathi</h6>

                <div className="flex items-center space-x-2">
                  <button className="text-sm bg-transparent border-0 rounded hover:bg-green-600 text-success hover:text-white" onClick={toggleFullScreen}>
                    {isFullScreen ? <IoContractOutline /> : <IoExpandOutline />}
                  </button>
                  <button className="text-sm bg-transparent border-0 rounded hover:bg-green-600 text-success hover:text-white" onClick={handleClose}>
                    <IoPowerOutline />
                  </button>
                </div>
            </header>

            <div ref={scrollableDivRef} className="mt-2 scrollable" style={{ height: isFullScreen ? 'calc(90vh - 160px)' : '255px', overflowY: 'scroll', width: isFullScreen ? '60rem' : '450px' }}>
              <div className="space-y-2">
                {messages.map((message, index) => (
                  <BotMessage key={index} message={message.message} time={message.time} isBot={message.isBot} />
                ))}
              </div>
            </div>

            <div className="mt-4 flex">
              <input type="text" placeholder="Type message..." value={question} onChange={(event) => setQuestion(event.target.value)} onKeyPress={handleKeyPress} className={`flex-1 p-2 small rounded rounded-full bg-green-700 text-white placeholder-zinc-300 ${isInputDisabled ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={isInputDisabled} />
              <button className="px-2 py-2 border-0 text-black bg-transparent" onClick={handleSubmit} disabled={isInputDisabled}>
                <div style={{ transform: 'rotate(90deg)' }}>
                  <TbNavigation className='text-success fa-2x' />
                </div>
              </button>
            </div>
            {isLoading && (
              <>
                <Spinner animation="grow" size="sm" />
                <Spinner animation="grow" />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MessageBox;
