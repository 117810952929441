import React from "react";
import { Link } from "react-router-dom";

function ButtonLink({ href, className, children }){
    const combinedClassName = `font-weight-bold mt-5 ${className}`;
    return(
        <>
        <Link className={combinedClassName} to={href}>
        {children} 
        </Link>
         {/* <a className={combinedClassName} href={href}>
            {children} </a> */}
        </>
    );
}

export default ButtonLink;