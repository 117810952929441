import React, { useState } from "react";
import { FormGroup } from 'react-bootstrap';
import {
    Container, Card, Col, Row, Form, Button, Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import { useEffect } from "react";
import axios from "axios";
import FetchApiMethod from "../../Helpers/FetchApiMethod";
import { encrytedString } from "../../Helpers/encrytedStr"
import { swalfirefunctionality } from "../../services/alertServices";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFormGroup, DateFormGroup, EmailFormGroup, TextAreaFormGroup } from "../../components/lib/formGroupEvent";
import shortid from "https://cdn.skypack.dev/shortid@2.2.16";
import useRazorpay from "react-razorpay";
import './index.css'
import "react-responsive-pagination/themes/minimal.css";
import { addMemberstudentvalidation, addMembervalidation, addMemberipvalidation } from "./validation";
import { FaRegTrashAlt } from 'react-icons/fa';
import Loading from '../../components/LoadingComponents/Loading'

function ICAIRegistrationForm() {
    let statnofchange = 'N'
    const [Razorpay] = useRazorpay();
    const [getAllCat, setAllCat] = useState([])
    const [selectedOption, setSelectedOption] = useState('CA Student');
    const [orderID, setOrderID] = useState('')
    const [selectedfile, SetSelectedFile] = useState([]);
    const [Files, SetFiles] = useState([]);
    const [partnershipdata, setPartnershipdata] = useState([])
    const [userdetails, setUserDetails] = useState([])
    const [price, setPrice] = useState(0)
    const [sepCat, setSepCal] = useState(true)
    const [sepCatsub, setSepCalsub] = useState('')
    const [cheDis, setCheDis] = useState(false)
    const [partnerUpload, setPartnerUpload] = useState(false)
    const [userBasicDetails, setUserBasicDetails] = useState(false)
    const [razpOrderID, setRazpOrderID] = useState('')
    const [email, setEmail] = useState({ 'email': '', "postman": 'Y' });
    const [getDataCount, setgetDataCount] = useState(0)
    const [datevalid, setDatevalid] = useState('N')
    const [blockcount, setBlockCount] = useState(0)
    const [loader, setLoader] = useState(false);
    const [noData, setNoData] =useState(false);
    const [phonemain, setPhonemain] = useState('')
    const [employeeData, setEmployeeData] = useState([{ "employeeId": 1, "name": "", "email": "" }, { "employeeId": 2, "name": "", "email": "" }, { "employeeId": 3, "name": "", "email": "" }, { "employeeId": 4, "name": "", "email": "" }, { "employeeId": 5, "name": "", "email": "" }, { "employeeId": 6, "name": "", "email": "" }, { "employeeId": 7, "name": "", "email": "" }])
    const [emailData, setEmailData] = useState({ "email" : "", "name": "", "membership_no": "", "organisation_name": '', "organisation_address": "", "organisation_gstin": "", "dob": "", "qual_date": "", "phone": "" })



    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const InputChange = (e) => {
        // --For Multiple File Input
        let images = [];
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.onloadend = () => {
                SetSelectedFile((preValue) => {
                    return [
                        ...preValue,
                        {
                            id: shortid.generate(),
                            filename: e.target.files[i].name,
                            filetype: e.target.files[i].type,
                            fileimage: reader.result,
                            datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                            filesize: filesizes(e.target.files[i].size)
                        }
                    ]
                });
            }
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
    }

    const DeleteSelectFile = (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = selectedfile.filter((data) => data.id !== id);
            SetSelectedFile(result);
        } else {
            // alert('No');
        }

    }

    const FileUploadSubmit = async (e) => {
        e.preventDefault();
        const filemain = document.getElementById('fileupload').files[0]
        const formdata = new FormData()
        formdata.append('file', filemain, filemain.name)
        const uploadimageapihandler = `https://icaigbnapi.proeffico.com/api/callFileUploadApi`
        const uploadImageRespo = await axios.post(uploadimageapihandler, formdata);
        const finalobject = uploadImageRespo?.data.data.partner_details
        console.log(finalobject, 'line no 105');
        setPartnershipdata(finalobject)
        if (finalobject.length > 2) {
            const finalextradata = finalobject.length - 2;
            const finalprice = 7500 + (2500 * finalextradata);
            setPrice(finalprice)
        } else {
            setPrice(7500)
        }
        setPartnerUpload(true);
    }

    const DeleteFile = async (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = Files.filter((data) => data.id !== id);
            SetFiles(result);
        } else {
            // alert('No');
        }
    }
    const inpracticeform = [
        { "id": 1, "component": EmailFormGroup, "ref": "Email", "name": "email", "required": "*", "type": "email", 'func1': 'onchangeEmail' },
        { "id": 1, "component": TextFormGroup, "ref": "Membership No", "name": "membership_no", "required": "*", "type": "text", 'func1': '', "value": emailData.membership_no },
        { "id": 1, "component": TextFormGroup, "ref": "Name", "name": "name", "required": "*", "type": "text", 'func1': '', "value": emailData.name },
        { "id": 1, "component": TextFormGroup, "ref": "Firm/Organisation", "name": "organisation_name", "required": "", "type": "text", 'func1': '', "value": emailData.organisation_name },
        { "id": 1, "component": TextFormGroup, "ref": "Firm/Organisation GSTIN", "name": "organisation_gstin", "required": "", "type": "text", 'func1': '', "value": emailData.organisation_gstin },
        { "id": 1, "component": DateFormGroup, "ref": "DOB", "name": "dob", "required": "*", "type": "date", 'func1': '', "value": emailData.dob },
        { "id": 1, "component": DateFormGroup, "ref": "Qualification Date", "name": "qual_date", "required": "*", "type": "date", 'func1': '', "value": emailData.qual_date },
        { "id": 1, "component": TextFormGroup, "ref": "Landline/Mobile No", "name": "phone", "required": "*", "type": "number", 'func1': '', "value": emailData.phone },
        { "id": 1, "component": TextFormGroup, "ref": "Firm/Organisation Address", "name": "organisation_address", "required": "*", "type": "text", 'func1': '', "value": emailData.organisation_address },
    ]

    const incorporateform = [
        { "id": 1, "component": EmailFormGroup, "ref": "Email", "name": "email", "required": "*", "type": "text", 'func1': 'onchangeEmail' },
        { "id": 1, "component": TextFormGroup, "ref": "Organisation Name", "name": "organisation_name", "required": "*", "type": "text", 'func1': '', "value": emailData.organisation_name },
        { "id": 1, "component": TextFormGroup, "ref": "Contact Number", "name": "phone", "required": "*", "type": "number", 'func1': '', "value": emailData.phone },
        { "id": 1, "component": TextFormGroup, "ref": "Organisation Address", "name": "organisation_address", "required": "*", "type": "text", 'func1': '', "value": emailData.organisation_address },
    ]

    const studentform = [
        { "id": 1, "component": EmailFormGroup, "ref": "Email", "name": "email", "required": "*", "type": "text", 'func1': 'onchangeEmail' },
        { "id": 1, "component": TextFormGroup, "ref": "Reg. No", "name": "membership_no", "required": "*", "type": "text", 'func1': '', "value": emailData.membership_no },
        { "id": 1, "component": TextFormGroup, "ref": "Student Name", "name": "name", "required": "*", "type": "text", 'func1': '', "value": emailData.name },
        { "id": 1, "component": TextFormGroup, "ref": "Contact Number", "name": "phone", "required": "*", "type": "number", 'func1': '', "value": emailData.phone },
        { "id": 1, "component": TextFormGroup, "ref": "Address", "name": "organisation_address", "required": "*", "type": "text", 'func1': '', "value": emailData.organisation_address },

    ]

    const getallcategory = async () => {
        setLoader(true)
        const getcatdata = {
            "postman": "Y"
        }
        const getcatres = FetchApiMethod("membership/getCategory", getcatdata, "POST");
        getcatres.then((res) => {
            setLoader(false)
            try {
                console.log(res, "data");
                if (res.status && res.message === "Success") {
                    setAllCat(res.data)
                } else {
                    swalfirefunctionality(`${res.message}`, "error", "error");
                }
            } catch (error) {
                swalfirefunctionality(`${error}`, "error", "error");
            }
        });
    }

    const handlechangeinputfields = (event) => {
        setSelectedOption(event.target.value)
    }

    const handleaddsubmit = async (data) => {
        setLoader(true)
        setUserBasicDetails(data)
        data.postman = 'Y'
        data.email = email.email
        if (selectedOption == 'CA Student') {
            data.student = 'Y'
            data.industry = 'N'
        } else if (selectedOption == 'Member In Industry (Corporate Member)') {
            data.student = 'N'
            data.industry = 'Y'
        } else if (selectedOption == 'Member In Practice (Other Member)') {
            data.student = 'N'
            data.industry = 'N'
        }
        const savememberres = FetchApiMethod("addMemberRegistration", data, "POST");
        savememberres.then((res) => {
            setLoader(false)
            console.log(res.data.qual_date, "1964-04-01", 'line no 198');
           console.log(new Date(res.data.qual_date) < new Date("1964-04-01"))
            if (res.status == true) {
                setCheDis(true);
                setUserDetails(res)
                if (res.data.hasOwnProperty('dob') && new Date(res.data.dob) < new Date("1964-04-01")) {
                    console.log('line no 165');
                    setPrice(3000)
                    statnofchange = "O"
                    setSepCalsub('Senior Member')
                } else if (res.data.hasOwnProperty('qual_date') && new Date(res.data.qual_date) > new Date("2022-04-01")) {
                    console.log('line no 168');
                    setPrice(3000)
                    statnofchange = 'Y'
                    setSepCalsub('Young Member')
                } else if (selectedOption == "CA Student") {
                    console.log('line no 171');
                    setPrice(2000)
                    setSepCal(false)
                } else if (selectedOption == "Member In Industry (Corporate Member)") {
                    console.log('line no 175');
                    setPrice(20000)
                    setSepCal(false)
                } else {
                    console.log('line no 179');
                    setSepCal(false)
                }
            }
        })
    }

    const deletpartner = (indexmain) => {
        if (partnershipdata.length > 2) {
            const filterdata = partnershipdata.filter((elem, index) => {
                return indexmain != index
            })
            setPartnershipdata(filterdata)
            if (filterdata.length > 2) {
                const finalextradata = filterdata.length - 2;
                const finalprice = 7500 + (2500 * finalextradata);
                setPrice(finalprice)
            } else {
                setPrice(7500)
            }
        } else {
            setPrice(7500)
        }
    }

    const handlechangeotherdata = (e) => {
        const changedvalue = e.target.value
        setSepCalsub(changedvalue);
        if (changedvalue == "Other CA Member") {
            setPrice(4000)
        }
    }

    const handlepayment = () => {
        setLoader(true)
        console.log(employeeData, 'line no 237');
        const data = {
            "cat": selectedOption,
            "sub_cat": sepCatsub,
            "price": price + (price * 0.18),
            'member_id': userdetails.length != 0 ? userdetails.data.member_id : emailData.membership_no
        }
        console.log(data, 'line no 200');
        const savemembertrancres = FetchApiMethod("addMemberMetadata", data, "POST");
        savemembertrancres.then((restre) => {
            if (restre.status == true) {
                setOrderID(restre.data.uuid)
                const orderdata = {
                    "amount": (price + (price * 0.18))*100,
                    "currency": 'INR',
                    "receipt": restre.data.uuid,
                    "notes": {
                        "notes_key_1": "ICAI Payment",
                    }
                }
                const createorderforRzpres = FetchApiMethod("membership/razorpay", orderdata, "POST");
                createorderforRzpres.then((res) => {
                    if (res.status = "true") {
                        setRazpOrderID(res.data.id);
                        const options = {
                            key: "rzp_live_9GsS48OEtXOB2m",
                            amount: (price + (price * 0.18))*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                            currency: "INR",
                            name: "ICAI",
                            description: "Live Transaction",
                            image: "/static/media/ICAI-Logo.f3fcefd6001e294a1682.png",
                            order_id: res.data.id,  //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
                            handler: function (response) {
                                const sdata = {
                                    "total_value": "Y",
                                    "txndate_processed": '',
                                    "ccbin": "NA",
                                    "oid": res.data.id,
                                    "cccountry": "",
                                    "expmonth": "NA",
                                    "merchantTransactionId": restre.data.uuid,
                                    "endpointTransactionId": "NA",
                                    "currency": 'INR',
                                    "processor_response_code": "NA",
                                    "chargetotal": price + (price * 0.18),
                                    "terminal_id": "Web",
                                    "approval_code": "NA",
                                    "expyear": "NA",
                                    "response_code_3dsecure": "NA",
                                    "notification_hash": "NA",
                                    "schemeTransactionId": "NA",
                                    "tdate": '',
                                    "installments_interest": "NA",
                                    "bname": "NA",
                                    "ccbrand": "NA",
                                    "refnumber": "NA",
                                    "txntype": "Sale",
                                    "paymentMethod": "M",
                                    "txndatetime": '',
                                    "cardnumber": "NA",
                                    "ipgTransactionId": response.razorpay_payment_id,
                                    "status": "APPROVED",
                                    "comments": "ICAI Payment",
                                    "ent_on": '',
                                    "json": JSON.stringify(response),
                                    "fail_reason": "NA",
                                }
                                const orderconfirmationrazorpay = FetchApiMethod("attendee_pay_response", sdata, "POST");
                                orderconfirmationrazorpay.then((resto) => {
                                    if (resto.message == 'success!') {
                                        const data = {
                                            'order_id': restre.data.uuid,
                                            'type': 'M',
                                            'postman': 'Y'
                                        }
                                        const orderconfirmationrazorpayget = FetchApiMethod("membership/get_attendee_pay_response", data, "POST");
                                        orderconfirmationrazorpayget.then((respo) => {
                                            console.log(respo.length, 'line no 292');
                                            if (respo.length > 0) {
                                                setLoader(false)
                                                swalfirefunctionality('Successfully Registered', "success", "success", true, `/Transaction-Status?id=${restre.data.uuid}&type=M`)
                                                // location.reload();
                                            } else {
                                                setLoader(false)
                                                swalfirefunctionality(`Something went wrong`, "error", "error", true, `/Transaction-Status?id=${restre.data.uuid}&type=M`)
                                                // location.reload();
                                            }
                                        })
                                    } else {
                                        setLoader(false)
                                        swalfirefunctionality(`${resto.message}`, "error", "error");
                                    }
                                })
                            },
                            prefill: {
                                name: `${noData == true ? selectedOption == 'Member In Industry (Corporate Member)' ? userdetails.data.organisation_name : userdetails.data.name : selectedOption == 'Member In Industry (Corporate Member)' ? emailData.organisation_name : emailData.name}`,
                                email: `${noData == false ? email.email : userdetails.data.email}`,
                                contact: `${noData == false ? phonemain : userdetails.data.phone}`,
                            },
                            theme: {
                                color: '#0d0d65',
                            },
                        };
                        const razorpay = new Razorpay(options);

                        razorpay.on("payment.failed", function (response) {
                            setLoader(false)
                            console.log(response);
                            const sdata = {
                                "total_value": "Y",
                                "txndate_processed": '',
                                "ccbin": "NA",
                                "oid": res.data.id,
                                "cccountry": "",
                                "expmonth": "NA",
                                "merchantTransactionId": restre.data.uuid,
                                "endpointTransactionId": "NA",
                                "currency": 'INR',
                                "processor_response_code": "NA",
                                "chargetotal": price + (price * 0.18),
                                "terminal_id": "Web",
                                "approval_code": "NA",
                                "expyear": "NA",
                                "response_code_3dsecure": "NA",
                                "notification_hash": "NA",
                                "schemeTransactionId": "NA",
                                "tdate": '',
                                "installments_interest": "NA",
                                "bname": "NA",
                                "ccbrand": "NA",
                                "refnumber": "NA",
                                "txntype": "Sale",
                                "paymentMethod": "M",
                                "txndatetime": '',
                                "cardnumber": "NA",
                                "ipgTransactionId": response.razorpay_payment_id,
                                "status": "DECLINED",
                                "comments": "ICAI Payment",
                                "ent_on": '',
                                "json": JSON.stringify(response),
                                "fail_reason": "NA",
                            }
                            const orderconfirmationrazorpaydec = FetchApiMethod("attendee_pay_response", sdata, "POST");
                            orderconfirmationrazorpaydec.then((resto) => {
                                if (resto.message == 'success!') {
                                    const data = {
                                        'order_id': restre.data.uuid,
                                        'type': 'M',
                                        'postman': 'Y'
                                    }
                                    const orderconfirmationrazorpayget = FetchApiMethod("membership/get_attendee_pay_response", data, "POST");
                                    orderconfirmationrazorpayget.then((respo) => {
                                        console.log(respo.length, 'line no 359');
                                        if (respo.length > 0) {
                                            setLoader(false)
                                            swalfirefunctionality('Successfully Registered', "success", "success")
                                            // location.reload();
                                        } else {
                                            setLoader(false)
                                            swalfirefunctionality(`Something went wrong`, "error", "error")
                                            // location.reload();
                                        }
                                    })
                                } else {
                                    setLoader(false)
                                    swalfirefunctionality(`${resto.message}`, "error", "error");
                                }
                            })
                        });

                        razorpay.open();
                    } else {
                        setLoader(false)
                        swalfirefunctionality(`${res.message}`, "error", "error");
                    }
                })
            } else {
                setLoader(false)
                swalfirefunctionality(`${restre.message}`, "error", "error");
            }
        })
    }

    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { errors, dirtyFields },
    } = useForm({
        resolver: yupResolver(selectedOption == "CA Student" ? addMemberstudentvalidation : selectedOption == 'Member In Practice (Other Member)' ? addMemberipvalidation : addMembervalidation),
    });

    useEffect(() => {
        getallcategory()
    }, []);

    const getEaildata = () => {
        const getEaildatares = FetchApiMethod("membership/getMemberRegistration", email, "POST");
        getEaildatares.then((res) => {
            if (res.data.length != 0 && email.email != "") {
                setNoData(false)
                setPhonemain(res.data[0].phone)
                if (res.data[0].annual_yn == "N") {
                    // setEmailData({ "name": res.data[0].name, "membership_no": res.data[0].membership_no, "organisation_name": res.data[0].organisation_name, "organisation_address": res.data[0].organisation_address, "organisation_gstin": res.data[0].organisation_gstin, "dob": res.data[0].dob, "qual_date": res.data[0].qual_date, "phone": encrytedString(res.data[0].phone, 'phone') })
                    if (res.data[0].registration_for == 'Student') {
                        setSelectedOption('CA Student')
                        setCheDis(true);
                        console.log('line no 171');
                        setPrice(2000)
                        setSepCal(false)
                        setEmailData({ "email" : res.data[0].email, "name": res.data[0].name, "membership_no": res.data[0].membership_no, "organisation_name": res.data[0].organisation_name, "organisation_address": res.data[0].organisation_address, "organisation_gstin": res.data[0].organisation_gstin, "dob": res.data[0].dob, "qual_date": res.data[0].qual_date, "phone": encrytedString(res.data[0].phone, 'phone') })
                    } else if (res.data[0].registration_for == 'Industry') {
                        setSelectedOption('Member In Industry (Corporate Member)')
                        console.log('line no 175');
                        setPrice(20000)
                        setSepCal(false)
                        setCheDis(true);
                        setEmailData({ "email" : res.data[0].email, "name": res.data[0].name, "membership_no": res.data[0].membership_no, "organisation_name": res.data[0].organisation_name, "organisation_address": res.data[0].organisation_address, "organisation_gstin": res.data[0].organisation_gstin, "dob": res.data[0].dob, "qual_date": res.data[0].qual_date, "phone": encrytedString(res.data[0].phone, 'phone') })
                    } else {
                        setSelectedOption('Member In Practice (Other Member)')
                        setCheDis(true);
                        setEmailData({"email" : res.data[0].email, "name": res.data[0].name, "membership_no": res.data[0].membership_no, "organisation_name": res.data[0].organisation_name, "organisation_address": res.data[0].organisation_address, "organisation_gstin": res.data[0].organisation_gstin, "dob": res.data[0].dob, "qual_date": res.data[0].qual_date, "phone": encrytedString(res.data[0].phone, 'phone') })
                    }
                    if (selectedOption == 'CA Student') {
                        setCheDis(true)
                        const encrptedphone = encrytedString(res.data[0].phone, 'phone')
                    } else if (selectedOption == 'Member In Industry (Corporate Member)') {
                        setCheDis(true)
                    } else {
                        setCheDis(true)
                    }
                    console.log(new Date(res.data[0].dob) > new Date("1964-04-01"), 'line no 475');
                    if (res.data[0].hasOwnProperty('dob') && new Date(res.data[0].dob) < new Date("1964-04-01")) {
                        console.log('line no 476');
                        statnofchange = "O"
                        setPrice(3000)
                        setSepCalsub('Senior Member')
                    } else if (res.data[0].hasOwnProperty('qual_date') && new Date(res.data[0].qual_date) > new Date("2022-04-01")) {
                        console.log('line no 168');
                        setPrice(3000)
                        statnofchange = "Y"
                        setSepCalsub('Young Member')
                    } else if (selectedOption == "CA Student") {
                       
                    } else if (selectedOption == "Member In Industry (Corporate Member)") {
                        
                    } else {
                        console.log('line no 179');
                        setSepCal(false)
                    }
                } else {
                    swalfirefunctionality('You are already a member', "success", "success", true, `/ICAI-Registration-From`)
                }
            }else{
                setNoData(true)
            }
        })
    }

    if (email.email != '') {
        console.log(email.email, 'line no 516');
        if (getDataCount == 0) {
            setgetDataCount(1)
            getEaildata();
        }
    } else if (email.email == '') {
        if (blockcount == 0) {
            setBlockCount(1);
            setEmailData({ "name": "", "membership_no": "", "organisation_name": '', "organisation_address": "", "organisation_gstin": "", "dob": "", "qual_date": "", "phone": "" })
            setCheDis(false);
        }
    }

    const onChangeInput = (e, employeeId) => {
        const { name, value } = e.target
        const editData = employeeData.map((item) =>
            item.employeeId === employeeId && name ? { ...item, [name]: value } : item
        )
        setEmployeeData(editData)
    }
    return (
        <>
            <Container fluid className={`p-0 mb-3 ${loader == false ? '' : 'd-none'}`}>
                <Container className="p-3 mt-3">
                    <Card className="border-3 rounded-3 shadow">
                        <Card.Header className="CAFirmInfoH4">
                            <h3>Registration Form</h3>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mx-2">
                                Category :
                                {getAllCat.map((elem) => (
                                    elem.id !== 3 &&
                                    <Col>
                                        <Form>
                                            <FormGroup controlId="formCheckbox">
                                                <Form.Check
                                                    type="radio"
                                                    label={elem.name}
                                                    name="category"
                                                    id={elem.name}
                                                    value={elem.name}
                                                    checked={selectedOption === elem.name}
                                                    onChange={handlechangeinputfields}
                                                    disabled={cheDis}
                                                />
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                ))}
                            </Row>
                            <Form
                                aria-autocomplete="false"
                                noValidate
                                onSubmit={handleSubmit(handleaddsubmit)}
                            >
                                <div className="mt-3" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
                                    {selectedOption == "Member In Practice (Other Member)" ? inpracticeform.map((elem) => (
                                        // <div className="mx-2" style={{ width: "45%" }}>
                                        <div className="col-lg-6 col-sm-12">
                                            <elem.component
                                                name={elem.name}
                                                placeholder={"Enter your " + elem.ref + "..."}
                                                label={elem.ref}
                                                register={register}
                                                errors={errors}
                                                dirtyFields={dirtyFields}
                                                required={elem.required}
                                                onchange='Y'
                                                setEmail={setEmail}
                                                setgetDataCount={setgetDataCount}
                                                setBlockCount={setBlockCount}
                                                optionType="Member In Practice"
                                                defaultValue={elem.value}
                                            />
                                        </div>
                                    )) : selectedOption == "CA Student" ? studentform.map((elem) => (
                                        <div className="col-lg-6 col-sm-12">
                                            <elem.component
                                                name={elem.name}
                                                placeholder={"Enter your " + elem.ref + "..."}
                                                label={elem.ref}
                                                register={register}
                                                errors={errors}
                                                dirtyFields={dirtyFields}
                                                required={elem.required}
                                                onchange='Y'
                                                setEmail={setEmail}
                                                setgetDataCount={setgetDataCount}
                                                setBlockCount={setBlockCount}
                                                optionType="CA Student"
                                                defaultValue={elem.value}
                                            />
                                        </div>
                                    )) : incorporateform.map((elem) => (
                                        <div className="col-lg-6 col-sm-12">
                                            <elem.component
                                                name={elem.name}
                                                placeholder={"Enter your " + elem.ref + "..."}
                                                label={elem.ref}
                                                register={register}
                                                errors={errors}
                                                dirtyFields={dirtyFields}
                                                required={elem.required}
                                                onchange='Y'
                                                setgetDataCount={setgetDataCount}
                                                setBlockCount={setBlockCount}
                                                setEmail={setEmail}
                                                optionType="Member in Industry"
                                                defaultValue={elem.value}
                                            />
                                        </div>
                                    ))}
                                </div>
                                {cheDis == false ?
                                    <Container className="p-0 my-3">
                                        <Row>
                                            <Col className="text-center">
                                                <Button variant="primary" type="submit">
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container> : ''}
                            </Form>
                        </Card.Body>
                        {cheDis != false && selectedOption == "Member In Practice (Other Member)" && sepCat == false ? <><Card.Header className="p-2 h4 CAFirmInfoH4">Sub-Category Details</Card.Header><Container className="w-100 d-flex justify-content-center align-items-center"><div class="input-group mb-3">
                            <select className="custom-select w-50 my-3" id="inputGroupSelect03" onChange={handlechangeotherdata}>
                                <option selected value="">Choose your sub category...</option>
                                {statnofchange == 'O' ? <option value="Senior Member">Senior Member</option> : statnofchange == 'Y' ? <option value="Young Member">Young Member</option> : <><option value="Other CA Member">Other CA Member</option>
                                    <option value="Partnership">Partnership</option></>}
                            </select>
                        </div></Container></> : cheDis != false && selectedOption == "Member In Practice (Other Member)" ? <><Card.Header className="p-2 h4 CAFirmInfoH4">Payment Details</Card.Header><Container className="w-100 d-flex justify-content-center align-items-center my-3">The annual registration fee for {selectedOption} categoary  {sepCatsub != '' ? `and sub - category ${sepCatsub}` : ''} is {price} plus GST at 18%, amounting to {price + (price * 0.18)}. <Button variant="primary" className="mx-3" type="submit" onClick={handlepayment}>Pay</Button></Container></> : ""}
                        {sepCatsub == "Partnership" ? <div className="fileupload-view">
                            <div className="row justify-content-center m-0">
                                <div className="col-md-6">
                                    <div className="mt-2">
                                        <div className="card-body1">
                                            <div className="kb-data-box">
                                                <div className="kb-modal-data-title">
                                                    <div className="kb-data-title">
                                                        <h6>Upload Your constitution File</h6>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="kb-file-upload">
                                                        <div className="file-upload-box">
                                                            <input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} multiple />
                                                            <span>Drag and drop or <span className="file-link">Choose your files</span></span>
                                                        </div>
                                                    </div>
                                                    <div className="kb-attach-box mb-3">
                                                        {
                                                            selectedfile.map((data, index) => {
                                                                const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                                return (
                                                                    <div className="file-atc-box" key={id}>
                                                                        {
                                                                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                                                <div className="file-image"><i className="far fa-file-alt"></i></div>
                                                                        }
                                                                        <div className="file-detail">
                                                                            <h6>{filename}</h6>
                                                                            <p></p>
                                                                            <p><span>Size : {filesize}</span><span className="ml-2">Modified Time : {datetime}</span></p>
                                                                            <div className="file-actions d-none">
                                                                                <button type="button" className="file-action-btn" onClick={() => DeleteSelectFile(id)}>Delete</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className="kb-buttons-box">
                                                        <button type="submit" className="btn btn-primary1 form-submit" onClick={FileUploadSubmit}>Upload</button>
                                                    </div>
                                                </div>
                                                {Files.length > 0 ?
                                                    <div className="kb-attach-box">
                                                        <hr />
                                                        {
                                                            Files.map((data, index) => {
                                                                const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                                return (
                                                                    <div className="file-atc-box" key={index}>
                                                                        {
                                                                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                                                <div className="file-image"><i className="far fa-file-alt"></i></div>
                                                                        }
                                                                        <div className="file-detail">
                                                                            <h6>{filename}</h6>
                                                                            <p><span>Size : {filesize}</span><span className="ml-3">Modified Time : {datetime}</span></p>
                                                                            <div className="file-actions">
                                                                                <button className="file-action-btn" onClick={() => DeleteFile(id)}>Delete</button>
                                                                                <a href={fileimage} className="file-action-btn" download={filename}>Download</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            : cheDis != false && sepCatsub != "Senior Member" && sepCatsub != "Young Member" && sepCatsub != '' ? <><Card.Header className="p-2 h4 CAFirmInfoH4">Payment Details</Card.Header><Container className="w-100 d-flex justify-content-center align-items-center my-3">The annual registration fee for {selectedOption} and subcategory {sepCatsub} is {price} plus GST at 18%, amounting to {price + (price * 0.18)}.  <Button variant="primary" className="mx-3" type="submit" onClick={handlepayment}>Pay</Button></Container></> : ''}
                        {partnershipdata.length != 0 ?
                            <div className="w-100 d-flex justify-content-center align-items-center">
                                <table className="table w-50" >
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Membership Number</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {partnershipdata.map((elem, index) => (
                                            <tr>
                                                <th scope="row">{index + 1}</th>
                                                <td>{elem.member_name}</td>
                                                <td>{elem['membership number']}</td>
                                                <td><FaRegTrashAlt onClick={(event) => { event.preventDefault(); deletpartner(index); }} style={{ color: "red", cursor: "pointer" }} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table></div> : ''}
                        {partnerUpload == true ? <><Card.Header className="p-2 h4 CAFirmInfoH4">Payment Details</Card.Header><Container className="w-100 d-flex justify-content-center align-items-center my-3">The annual registration fee for {selectedOption} is {price} plus GST at 18%, amounting to {price + (price * 0.18)}. <Button variant="primary" className="mx-3" type="submit" onClick={handlepayment}>Pay</Button></Container></> : ''}
                        {cheDis == true && partnerUpload != true && selectedOption != "Member In Practice (Other Member)" ? selectedOption != "Member In Industry (Corporate Member)" ? <><Card.Header className="p-2 h4 CAFirmInfoH4">Payment Details</Card.Header><Container className="w-100 d-flex justify-content-center align-items-center my-3">The annual registration fee for {selectedOption} is {price} plus GST at 18%, amounting to {price + (price * 0.18)}. <Button variant="primary" className="mx-3" type="submit" onClick={handlepayment}>Pay</Button></Container></> : <><Card.Header className="p-2 h4 CAFirmInfoH4">Member Details</Card.Header>
                        <Container className="d-flex justify-content-center align-items-center">Note -: The annual registration fee for Member in Industry or Corporate Members is ₹20,000 plus GST at 18%, amounting to ₹23,600. This fee covers up to 7 members.
                        For additional members beyond the initial seven, please fill the separate registration form again.</Container>
                        <Container className="d-flex justify-content-center align-items-center">
                        <table className="table table-responsive maintable my-2"><thead>
                            <tr>
                                <th>Membership Number</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Contact No</th>
                            </tr>
                        </thead><tbody>
                                {employeeData.map((elem, index) => (
                                    <tr>
                                        <td>
                                            <input
                                                name="membershipname"
                                                type="text"
                                                onChange={(e) => onChangeInput(e, employeeData.employeeId)}
                                                placeholder="Membership No"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name="name"
                                                type="text"
                                                onChange={(e) => onChangeInput(e, employeeData.employeeId)}
                                                placeholder="Enter Name"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name="email"
                                                type="email"
                                                onChange={(e) => onChangeInput(e, employeeData.employeeId)}
                                                placeholder="Email"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name="phone"
                                                type="text"
                                                onChange={(e) => onChangeInput(e, employeeData.employeeId)}
                                                placeholder="Contact No"
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody></table>
                            </Container>
                            <Card.Header className="p-2 h4 CAFirmInfoH4">Payment Details</Card.Header>
                            <Container className="w-100 justify-content-center align-items-center my-3">
                                <Row className="justify-content-center align-items-center">
                                    The annual registration fee for {selectedOption} is {price} plus GST at 18%, amounting to {price + (price * 0.18)}.
                                    <Button variant="primary" className="mx-3" type="submit" onClick={handlepayment}>Pay</Button>
                                </Row>
                            </Container>
                        </> : ''}


                    </Card>
                </Container>

            </Container >
            <Loading loader={loader} />
        </>
    );
}

export default ICAIRegistrationForm;
