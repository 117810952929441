import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { Button, Row, Col, Container } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { applyPostSchema } from "./validation";
import { TextFormGroup,EmailFormGroup } from "components/lib/formGroup";
export default function ApplyPost(props) {
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(applyPostSchema),
  });

  return (
    <Form noValidate onSubmit={handleSubmit(props.onSubmit)}>
      <Container className="p-0">
        <Row>
          <Col>
            <TextFormGroup
              label="Position"
              name="position"
              placeholder="Enter name here..."
              register={register}
              errors={errors}
              dirtyFields={dirtyFields}
              required="*"
              readonly="readonly"
              defaultValue={props.data.position}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextFormGroup
              label="Name"
              name="name"
              placeholder="Enter name here..."
              register={register}
              errors={errors}
              dirtyFields={dirtyFields}
              required="*"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextFormGroup
              label="Phone"
              name="phone"
              placeholder="Whatsapp number required..."
              register={register}
              errors={errors}
              dirtyFields={dirtyFields}
              required="*"
            />
          </Col>
        </Row>
        
        <Row>
          <Col>
            <EmailFormGroup
              register={register}
              errors={errors}
              dirtyFields={dirtyFields}
              name="email"
              label="Email"
              placeholder="Enter email..."
            />
          </Col>
        </Row>
      </Container>
      <Container className="d-flex justify-content-end">
      <Button variant="primary" className="btn-sm ModalSubmitBtn" type="submit">
        Apply
      </Button>
      <Button variant="secondary" className="btn-sm modalCloseBtn" type="button" onClick={props.onHide}>
        Close
      </Button>
      </Container>
    </Form>
  );
}
