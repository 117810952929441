import React from "react";
import { Button, Card, CardBody, Row, Col } from 'reactstrap';
import ButtonLink from "views/IndexCompnents/ButtonsSection/ButtonLink";
import { Link } from "react-router-dom";


function IcaiResources(){

return(
    <>
     <section className="section bg-light pt-4">
        <div className="container-fluid justify-content-center">
            <h3 className="display-3 text-center allMainHeading marginLeftResources mb-0 pb-4">ICAI Resources</h3>
<Row className="justify-content-center align-items-center">
   {/* <Col lg="4"  className="mb-3">
    <Card className="bg-primary" style={{height:"15rem"}}>
        <CardBody className="text-center align-content-center  shadow-sm">
            <h2 className="text-white heading-title">Our Expertise <span><ButtonLink className="text-white"><i className="fa fa-long-arrow-right"/></ButtonLink></span></h2>
           
        </CardBody>
    </Card>

    </Col> */}

   <Col lg="4"  className="mb-3">
     <Card style={{height:"15rem"}}>
        <CardBody className="text-center align-content-center   shadow-sm">
           <i className="ni ni-world-2 fa-2x text-primary"/>
           {/* <i className="fa fa-twitter" /> */}
            <h5 className="heading">
                ICAI Digital Learning Hub
            </h5>
            <Button> <Link to="https://learning.icai.org/iDH/icai/" target="_blank">View <i className="fa fa-long-arrow-right"/></Link></Button>
        </CardBody>
    </Card>
    </Col>

   <Col lg="4"  className="mb-3">
     <Card style={{height:"15rem"}}>
        <CardBody className="text-center align-content-center   shadow-sm">
           <i className="ni ni-book-bookmark fa-2x text-primary"/>
            <h5 className="heading">
                ICAI Publication - CDS Portal
            </h5>
            <Button>
            <Link to="https://icai-cds.org/" rel="bookmark" target="_blank">View <i className="fa fa-long-arrow-right"/>
               </Link></Button>
        </CardBody>
    </Card>
    </Col>
    
   <Col lg="4"  className="mb-3">
     <Card style={{height:"15rem"}}>
        <CardBody className="text-center align-content-center   shadow-sm">
           <i className="fa fa-users fa-2x text-primary"/>
            <h5 className="heading">
              Committee for Member in Practice
            </h5>
            <Button><Link to="https://cmpbenefits.icai.org/" target="_blank">View <i className="fa fa-long-arrow-right"/></Link></Button>
        </CardBody>
    </Card>
    </Col>

   <Col lg="4"  className="mb-3">
     <Card style={{height:"15rem"}}>
        <CardBody className="text-center align-content-center   shadow-sm">
           <i className="ni ni-settings fa-2x text-primary"/>
            <h5 className="heading">
                ICAI Self Service Portal
            </h5>
            <Button><Link to="https://eservices.icai.org/per/g21/pub/1666/SelfServices/templates/Login%20Folder21052019122446/Login%20Folder/ICAI%20Phase%20II%20Login%20Page521052019122546.html" target="_blank">View <i className="fa fa-long-arrow-right"/></Link></Button>
        </CardBody>
    </Card>
    </Col>

   <Col lg="4"  className="mb-3">
     <Card style={{height:"15rem"}} >
        <CardBody className="text-center align-content-center   shadow-sm">
           <i className="ni ni-hat-3 fa-2x text-primary"/>
            <h5 className="heading">
                ICAI E-learning Portal
            </h5>
            <Button ><Link to="https://learning.icai.org/elearning" target="_blank">View <i className="fa fa-long-arrow-right"/></Link></Button>
        </CardBody>
    </Card>
    </Col>
    <Col lg="4"  className="mb-3">
        <Card className="bg-primary" style={{height:"15rem"}}>
            <CardBody className="text-center align-content-center  shadow-sm">
                <ButtonLink className="text-white" href="/OurResources"> 
                <h2 className="text-white heading-title">View Others  <span><i className="fa fa-long-arrow-right"/></span></h2>
                
                </ButtonLink>
            </CardBody>
        </Card>

    </Col>
  
</Row>
        </div>
        </section>
    </>
);

}

export default IcaiResources;