import * as Yup from "yup";

export const vacancyAddSchema = Yup.object().shape({
  firm_name: Yup.string().required().matches(/^[A-Z a-z]+$/, "Must be only letters"),
  contact_person: Yup.string().required("contact person is required!"),
  frn_no: Yup.string().required("FRN is required!"),
  pincode: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
  stipend_range: Yup.string().required("Stipend range is required!").matches(/^[0-9]+$/, "Must be only digits"),
  city: Yup.string().required().matches(/^[A-Z a-z]+$/, "Must be only letters"),
  address: Yup.string().required("Address is required!"),
  position: Yup.string().required("Position is required!").matches(/^[A-Z a-z]+$/, "Must be only letters"),
  // email: Yup.string().required("Email is required").email("Email is invalid"),
  contact_no: Yup.string().matches(/^[6-9]\d{9}$/,"Please enter valid number."
  ),
});


