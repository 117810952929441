import React, { useState } from "react";
import { Card, CardImg, Row, Col, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import "../../app.css";
import { FaArrowRight, FaArrowLeft, FaEye, FaDownload } from "react-icons/fa";
import { imageURL } from "components/URLs/URL";


function formatDate(dateString) {
    const date = new Date(dateString);
    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(); // Get number of days in the month
    let day = date.getDate();
    const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthAbbreviations[date.getMonth()];
    const year = date.getFullYear();
    
    // Adjust day if it's the last day of the month
    if (day === daysInMonth) {
        day = '31';
    }

    return `${day}-${month}-${year}`;
}

const OurGalleryComponents = (props) => {
  const { photosData } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const openModal = (imageUrl, event, index) => {
    setSelectedImage(imageUrl);
    setSelectedEvent(event);
    setSelectedIndex(index);
    toggleModal();
  };

  const handlePrev = () => {
    if (selectedEvent && selectedIndex > 0) {
      const newIndex = selectedIndex - 1;
      setSelectedImage(selectedEvent.social_links[newIndex].image_url);
      setSelectedIndex(newIndex);
    }
  };

  const handleNext = () => {
    if (selectedEvent && selectedIndex < selectedEvent.social_links.length - 1) {
      const newIndex = selectedIndex + 1;
      setSelectedImage(selectedEvent.social_links[newIndex].image_url);
      setSelectedIndex(newIndex);
    }
  };


  const handleDownload = () => {
    if (selectedImage) {
      var element = document.createElement("a");
      var file = new Blob(
        [
          selectedImage
        ],
        { type: "image/*" }
      );
      element.href = URL.createObjectURL(file);
      element.download = selectedImage.split('/').pop();
      element.click();
    }

  };

  return (
    <section className="section bg-gradient" style={{ minHeight: "100vh" }}>
      <div className="container-fluid">
        <h1 className="px-3 display-3 allMainHeading">Our Gallery</h1>
        {photosData.map((event, index) => (
          <div key={index}>
            <h4 className="mx-3 mt-4 allSubHeading">{event.title}&nbsp;({formatDate(event.start_date)})</h4>
            <Row className="px-3 pt-4 flex-nowrap overflow-auto mb-2">
              {event.social_links.slice(0, 4).map((image, i) => (
                <Col className="mb-3 galleryMargin" xs="auto" key={`${index}_${i}`}>
                  {/* <Card className="" style={{ borderRadius: "50rem" }}> */}
                    <CardImg className="logosImag"
                      alt="Event Image"
                      src={`${imageURL}/public/img/${image.image_url}`}
                      top
                      onClick={() => openModal(image.image_url, event, i)}
                    />
                  {/* </Card> */}
                </Col>
              ))}
              {event.social_links.length > 0 && (
                <Row className="justify-content-end imageButton" style={{ marginTop: "5%" }}>
                  <Col xs="auto">
                    <Button
                      href={event.drive_link}
                      target="_blank"
                      style={{ color: "#05007E" }}
                    >
                      View More <FaEye />
                    </Button>
                  </Col>
                </Row>
              )}
            </Row>
          </div>
        ))}
      </div>
      <Modal isOpen={modalOpen} toggle={toggleModal} size="xl">
        <ModalHeader toggle={toggleModal}>Event Photo</ModalHeader>
        <ModalBody className="text-center">
          <img src={`https://icaigbnapi.proeffico.com/public/img/${selectedImage}`} alt="Enlarged Event Image" style={{ maxWidth: "100%", maxHeight: "70vh" }} />
          <div className="d-flex justify-content-between mt-3">
            <Button color="primary" onClick={handlePrev} disabled={selectedIndex === 0}>
              <FaArrowLeft /> Previous
            </Button>
            <Button color="success" onClick={handleDownload}>
              Download <FaDownload />
            </Button>
            <Button color="primary" onClick={handleNext} disabled={selectedIndex === (selectedEvent ? selectedEvent.social_links.length - 1 : 0)}>
              Next <FaArrowRight />
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </section>
  );
};

export default OurGalleryComponents;
