import React, { useState } from "react";
import {
  TextFormGroup,
  EmailFormGroup,
  TextAreaFormGroup,
} from "components/lib/formGroup";
import axios from "axios";
import { headers_image_upload } from "Helpers/FetchApiMethod";
import { Card, Container, Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { swalfirefunctionality } from "services/alertServices";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { FileFormGroup } from "components/lib/formGroup";
import { vacancyAddSchema } from "./validation";
import { useNavigate } from "react-router-dom";

function AddVacancy() {
  const navigate = useNavigate();
  const [firmImageName, setFirmImageName] = useState(undefined);
  async function imageUploadResult(event) {
    const fileValue = event.target.files[0];
    if (fileValue.size <= 1024 * 1024) {
      let imageFileName = Date.now() + "_" + fileValue.name;
      let newIpFormData = new FormData();
      newIpFormData.append("folderName", "images");
      newIpFormData.append("file", fileValue, fileValue.name);
      newIpFormData.append(
        "imageName",
        imageFileName.replaceAll(" ", "").split(".")[0]
      );
      const uploadImageRespo = await axios.post(
        "https://icaigbnapi.proeffico.com/api/image_upload",
        newIpFormData,
        { headers: headers_image_upload }
      );
      try {
        if (uploadImageRespo.data != undefined) {
          if (uploadImageRespo.data.status == true) {
            console.log(
              uploadImageRespo.data.data,
              "uploadImageRespo.data.data"
            );
            setFirmImageName(uploadImageRespo.data.data);
            swalfirefunctionality(
              "Good Job",
              `${uploadImageRespo.data.message}`,
              "success"
            );
          } else {
            swalfirefunctionality("error", "Your image not uploaded", "error");
          }
        } else {
        }
      } catch (error) {
        swalfirefunctionality("error", `${error}`, "error");
      }
    } else {
      swalfirefunctionality("error", "Image should be less than 2 MB", "error");
    }
  }

  const handleAddSubmit = (data) => {
    let reqData = data;
    reqData.email_id = reqData.email;
    reqData.image = firmImageName;
    reqData.created_by = "1";
    console.log(reqData, "reqData");
    const add_vacancy_api_result = FetchApiMethod(
      "addArticleshipplacement",
      reqData,
      "POST"
    );
    add_vacancy_api_result.then((res) => {
      try {
        if (res.message === "Articleship placement created successfully") {
          console.log(res, "res");
          swalfirefunctionality(
            `${res.data.firm_name} is added successfully`,
            "Good Job",
            "success",
          );
          reset();
          navigate("/articals");
        } else {
          swalfirefunctionality(`${res.error.message}`, "error", "error");
        }
      } catch (error) {
        swalfirefunctionality(`${error}`, "error", "error");
      }
    });
  };
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(vacancyAddSchema),
  });

  return (
    <Container fluid className="p-0 mb-3">
      <Form
        aria-autocomplete="false"
        noValidate
        onSubmit={handleSubmit(handleAddSubmit)}
      >
        <Container className="p-3 mt-3">
          <Card className="border-3 rounded-3 shadow">
            <Card.Header className="p-0 h3 px-3 allMainHeading">
              Submit a vacancy
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <TextFormGroup
                    name="firm_name"
                    placeholder="Enter firm..."
                    label="Firm Name"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                  />
                </Col>
                <Col>
                  <TextFormGroup
                    name="frn_no"
                    placeholder="Enter firm no..."
                    label="Firm Registration No."
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <FileFormGroup
                    name="image"
                    label="Firm Logo"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    imageUpload={imageUploadResult}
                  />
                </Col>

                <Col>
                  <TextFormGroup
                    name="contact_person"
                    placeholder="Enter contact person..."
                    label="Contact Person"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EmailFormGroup
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    name="email"
                    label="Email"
                    placeholder="Enter email..."
                  />
                </Col>
                <Col>
                  <TextFormGroup
                    name="contact_no"
                    placeholder="Enter contact no..."
                    label="Contact No."
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                    minlength="10"
                    maxlength="10"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <TextFormGroup
                    name="position"
                    placeholder="Enter position..."
                    label="Position"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                  />
                </Col>
                <Col>
                  <TextFormGroup
                    name="stipend_range"
                    placeholder="Enter stipend..."
                    label="Stipend Range"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <TextFormGroup
                    name="pincode"
                    placeholder="Enter pincode..."
                    label="Pincode"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                    maxlength="6"
                    minlength="6"
                  />
                </Col>
                <Col>
                  <TextFormGroup
                    name="city"
                    placeholder="Enter city..."
                    label="City"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <TextAreaFormGroup
                    name="address"
                    placeholder="Enter address..."
                    label="Address"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                    row={2}
                  />
                </Col>
              </Row>
              <Container className="p-0">
                <Row>
                  <Col className="text-right">
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Container>
      </Form>
    </Container>
  );
}

export default AddVacancy;
