import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "reactstrap";
import ProfileCard from "views/IndexCompnents/ImagesSection/ProfileCard";
import '../IcaiPages/groweffect.css';

function ChairmanProfile() {
  const [chairmandata, setChairmanProfileMsg] = useState(null);
  const [error, setError] = useState(null);

  const fetchChairmanProfileMsg = async () => {
    try {
      const apiUrl = "https://icaigbnapi.proeffico.com/api/getChairma?postman=Y";
      const settings = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
        },
      };
      const response = await fetch(apiUrl, settings);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      setChairmanProfileMsg(data.data[0] || null);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchChairmanProfileMsg();
  }, []);

  if (error) {
    return (
      <Container className="text-center mt-5">
        <p>Error: {error}</p>
      </Container>
    );
  }

  return (
    <section className="section">
      <h4 className="chairmanPageCSSMsg display-3 allMainHeading">Chairman Message</h4>
      <div className="container-fluid chairmanPageCSS">
        <Card className="card-profile shadow mt--500">
          <div className="px-4">
            <Row className="align-items-top justify-content-center">
              <Col className="order-lg-2 p-2 pt-4" lg="3" sm="12">
                {chairmandata && (
                  <ProfileCard
                    alt="abc"
                    src={`https://icaigbnapi.proeffico.com/public/img/${chairmandata.images}`}
                    title={chairmandata.name}
                  >
                    <div className="bg-light w-100 rounded-bottom pl-2">
                      <p className="mb-0 py-1 h6">{chairmandata.address}</p>
                    </div>
                  </ProfileCard>
                )}
              </Col>
              <Col className="order-lg-3 p-2 pt-3" lg="9" sm="12">
                <p className="lead mt-0">Dear Colleagues,</p>
                <p className="para text-sm text-justify">
                  {chairmandata?.description && chairmandata.description.split('<br>').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </Col>
            </Row>
            <Row>
              <p className="para text-sm text-justify pt-2">
                {chairmandata?.designation_1 &&
                  chairmandata.designation_1.split('<br>').map((line2, index) => (
                    <React.Fragment key={index}>
                      <Col lg={12} className="mx-0 px-2">
                        {line2}
                        <br />
                      </Col>
                    </React.Fragment>
                  ))}
              </p>
            </Row>
          </div>
        </Card>
      </div>
    </section>
  );
}

export default ChairmanProfile;
