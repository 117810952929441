import React, { useState } from "react";
import {
  Container,
  Card,
  Col,
  Row,
  Form,
  Button,
  Tooltip,
  OverlayTrigger,
  CardText,
} from "react-bootstrap";
import { useEffect } from "react";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { swalfirefunctionality } from "services/alertServices";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFormGroup, SelectFormGroup } from "components/lib/formGroup";
import { useNavigate } from "react-router-dom";
import { ModalAdd } from "./modal";
import ResponsivePagination from "react-responsive-pagination";
import { networkingZoneSchemaFilter } from "./validation";
import SearchBox from "SearchBox/searchBox";
import { debounce } from "Helpers/Debounce";
import { imageURL } from "components/URLs/URL";

function NetworkingZone() {
  const [netZone, setNetZone] = useState(null);
  const [clearFilter, setClearFilter] = useState(false);
  const [showAddModal, setshowAddModal] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [selectedData, setSelected] = useState(null);
  const [enteries, setEnteries] = useState(null);
  const [inputFlag,setInputFlag]=useState(false);
  const [category, setCategory] = useState([
    { value: "Networking", label: "Networking" },
    { value: "Partner", label: "Partner" },
    { value: "Merger_Acquisition", label: "Merger & Acquisition" },
    { value: "Resources", label: "Resources" },
    { value: "Others", label: "Others" },
  ]);
  const [filterBtn, setFilterBtn] = useState(true);
  const [FilterLabel, setFilterLabel] = useState(null);

  const [networkZoneDropDwnData, setNetWorkZoneDropDwnData] = useState(null);
  const [selectedIp, setSelectedIp] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/addNetworkZone");
  };


  const handleChangeSearch = (e) => {
    setSelectedIp(null);
    setSearchTerm(e.target.value);
  };
  const handleFilterNetworkSubmit = (data) => {
    if (selectedIp || data.category != "" || data.location != "") {
      const reqDataForFilter = data;
      reqDataForFilter.position = selectedIp;
    const filter_network_zone_api_result = FetchApiMethod(
      "getNetworkZone",
      data,
      "POST"
    );
    filter_network_zone_api_result.then((res) => {
      try {
        if (res.status && res.message === "success") {
          setNetZone(res.data);
          setFilterBtn(false);
          setFilterLabel(res.data[0].category);
        } else {
          // swalfirefunctionality(`${res.message}`, "error", "error");
          setFilterBtn(true);
          setFilterLabel("0");

        }
      } catch (error) {
        swalfirefunctionality(`${error}`, "error", "error");
      }
    });
  }else{
    swalfirefunctionality("Inputs are required", "error", "error");
  }
  };
  const handleAddSubmit_NetworkZone = (data) => {
    data.job_id = jobId;
    data.job_type = "NetZone";
    const apply_post_api_result = FetchApiMethod("Applyjobs", data, "POST");
    apply_post_api_result.then((res) => {
      try {
        if (
          res.message === "Apply job successfully" ||
          res.message === "Network zone created successfully"
        ) {
          reset();
          setshowAddModal(false);
          swalfirefunctionality(`${res.message}`, "Good job", "success");
        } else {
          swalfirefunctionality(`${res.message}`, "error", "error");
          setshowAddModal(false);
        }
      } catch (error) {
        swalfirefunctionality(`${error}`, "error", "error");
        setshowAddModal(false);
      }
    });
  };

  const getNetZone = () => {
    let paginationData = {
      pagination: {
        pagesize: perPage,
        pagenumber: currentPage,
      },
      q: searchTerm,
    };
    const getNetZonesResult = FetchApiMethod(
      "getNetworkZone",
      paginationData,
      "POST"
    );
    getNetZonesResult.then((res) => {
      try {
        if (res.status && res.message === "success") {
         if(!searchTerm){
           setNetZone(res.data)
           setFilterLabel(null);
           setTotalPages(res.last_page);
          setCurrentPage(res.current_page);
          setPerPage(res.per_page);
          setEnteries(res.line);
         }else{
           setNetWorkZoneDropDwnData(res.data);
          setTotalPages(res.last_page);
          setCurrentPage(res.current_page);
          setPerPage(res.per_page);
          setEnteries(res.line);
          setSelectedIp(null);
         }
        } else {
          setNetWorkZoneDropDwnData(null);
          // swalfirefunctionality(`${res.message}`, "error", "error");
        }
      } catch (error) {
        swalfirefunctionality(`${error}`, "error", "error");
      }
    });
  };

  const clearFilterData = () => {
    setClearFilter(true);
    setFilterLabel(null);
    setSelectedIp(null);
    setSearchTerm(null)
    reset();
  };

  const handleAddModal = async () => {
    setshowAddModal(true);
  };

  useEffect(() => {
    getNetZone();
  }, [clearFilter, currentPage, searchTerm]);

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(networkingZoneSchemaFilter),
  });

  const networkZoneDropDwnSearch = debounce(handleChangeSearch);

  const handleGetValue = (getValue) => {
    setSelectedIp(getValue);
  };

const handleInputChange=(value)=>{
  inputFlag? setInputFlag(true):setInputFlag(false);
}
  return (
    <Container fluid className="netZoneContainer p-0 mt-3">
      <h1 className="px-3 display-3 allMainHeading">Networking zone</h1>
      <Container fluid className="p-0">
        <Row>
          <Col md={3}></Col>
          <Col>
          {FilterLabel &&  (
          <Card.Text className="p-0 text-start text-dark mb-2 ml-3">
          {`Result ${FilterLabel}  `}
            <Button
              onClick={clearFilterData}
              className={`${
                filterBtn ? "netZoneBtnClrFilter" : "netZoneBtnApllyFilter"
              }`}
            >
              <em
                class="fa fa-times"
                aria-hidden="true"
              ></em>
            </Button>
          </Card.Text>
        )}
          </Col>
        </Row>
        
        <Row>
          <Col md={3}>
            <Form noValidate onSubmit={handleSubmit(handleFilterNetworkSubmit)}>
              <Card className="border-2 rounded-3 shadow mb-2 ml-3">
                <Card.Header className="p-2">
                  <em className="mx-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.4375 6.5625H1.5625C1.31386 6.5625 1.0754 6.46373 0.899587 6.28791C0.723772 6.1121 0.625 5.87364 0.625 5.625C0.625 5.37636 0.723772 5.1379 0.899587 4.96209C1.0754 4.78627 1.31386 4.6875 1.5625 4.6875H18.4375C18.6861 4.6875 18.9246 4.78627 19.1004 4.96209C19.2762 5.1379 19.375 5.37636 19.375 5.625C19.375 5.87364 19.2762 6.1121 19.1004 6.28791C18.9246 6.46373 18.6861 6.5625 18.4375 6.5625ZM15.3125 10.9375H4.6875C4.43886 10.9375 4.2004 10.8387 4.02459 10.6629C3.84877 10.4871 3.75 10.2486 3.75 10C3.75 9.75136 3.84877 9.5129 4.02459 9.33709C4.2004 9.16127 4.43886 9.0625 4.6875 9.0625H15.3125C15.5611 9.0625 15.7996 9.16127 15.9754 9.33709C16.1512 9.5129 16.25 9.75136 16.25 10C16.25 10.2486 16.1512 10.4871 15.9754 10.6629C15.7996 10.8387 15.5611 10.9375 15.3125 10.9375ZM11.5625 15.3125H8.4375C8.18886 15.3125 7.9504 15.2137 7.77459 15.0379C7.59877 14.8621 7.5 14.6236 7.5 14.375C7.5 14.1264 7.59877 13.8879 7.77459 13.7121C7.9504 13.5363 8.18886 13.4375 8.4375 13.4375H11.5625C11.8111 13.4375 12.0496 13.5363 12.2254 13.7121C12.4012 13.8879 12.5 14.1264 12.5 14.375C12.5 14.6236 12.4012 14.8621 12.2254 15.0379C12.0496 15.2137 11.8111 15.3125 11.5625 15.3125Z"
                        fill="black"
                      />
                    </svg>
                  </em>
                  Filter
                </Card.Header>
                <Card.Body className="p-0">
                  <Container className="p-0">
                    <Col>
                      <SelectFormGroup
                        name="category"
                        placeholder="Select..."
                        label="Category"
                        register={register}
                        errors={errors}
                        dirtyFields={dirtyFields}
                        options={category}
                        handleInputChange={(value)=>handleInputChange(value)}
                      />
                    </Col>
                   
                    <Col>
                       <SearchBox
                        searchData={searchTerm? networkZoneDropDwnData:[]}
                        handleChangeSearch={networkZoneDropDwnSearch}
                        label="Search Position Here..."
                        handleGetValue={handleGetValue}
                        selectedIp={selectedIp}
                        
                      />
                    </Col>
                    {/* <Col>
                      <TextFormGroup
                        name="location"
                        placeholder="Enter location..."
                        label="Location"
                        register={register}
                        errors={errors}
                        dirtyFields={dirtyFields}
                        handleInputChange={handleInputChange}
                      />
                    </Col> */}
                  </Container>
                  <Row>
                    <Col className="mt-2 mb-2 text-center">
                      <Button 
                      
                        type="submit"
                        className={`${
                          filterBtn
                            ? "netZoneBtnApllyFilter"
                            : "netZoneBtnClrFilter"
                        }`}
                      >
                        Apply Filter
                      </Button>

                      <OverlayTrigger
                        key={"top"}
                        placement={"top"}
                        overlay={
                          <Tooltip>
                            Do you want to submit a <strong>{"post"}</strong>.
                          </Tooltip>
                        }
                      >
                        <Button
                          onClick={handleClick}
                          className="submitVacancyBtn"
                        >
                          Submit Post
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Form>
          </Col>
          <Col>
            <Container fluid className="netZoneCardContainer p-0">
              {netZone ? (
                netZone?.map((ele, index) => (
                  ele.event_status=="Active" &&
                  <Card key={`articles${index}`} className="border-2 rounded-3 shadow mb-2 mx-3">
                    <Card.Body className="p-1">
                      <Container fluid className="d-flex justify-content-between">
                        <Col xs={2} className="p-0">
                          <Container className="articalImg p-0">
                            <Card.Img
                              className="img"
                              variant="top"
                              src={
                                ele.image
                                ? `${imageURL}public/img/${ele.image}`
                                : `${imageURL}public/img/663391adccf81.png`
                              }
                            />
                          </Container>
                        </Col>
                        <Col className="p-0">
                          <Row>
                            <Col>
                              <CardText className="text-primary text-xs text-right p-0">
                               <small className="mr-1">{new Date(ele.created_at).toDateString()} </small>
                              <small className="text-dark">
                                Position will be removed after 
                              </small>
                              <small className="text-warning font-weight-bold">
                                 {` (${ele.days_left}-days)` ?? "N.A"}
                              </small>
                            </CardText>
                            </Col>
                          </Row>
                          <Card.Title className="m-0">
                          <small className="CAFirmInfoH4 text-uppercase">
                            {ele.position ?? "N.A"}
                          </small>
                          </Card.Title>
                          {ele.total_vacancy &&   <Card.Text className="text-xs m-0"> <small>Total Vacancy 
                               <strong className="text-dark font-weight-bold mx-1">{ele.total_vacancy ?? "N.A"}</strong></small>
                              </Card.Text>
                              }
                          <Row>
                            <Col>
                              <Card.Text>
                                <small>
                                  Name
                                  <span className="text-dark font-weight-bold">
                                    : {ele.name}
                                  </span>
                                </small>
                              </Card.Text>
                            </Col>

                            <Col className="">
                              <Card.Text>
                                <small>
                                  Phone
                                  <span className="text-dark font-weight-bold ">
                                    : {ele.contact_number ?? "N.A"}
                                  </span>
                                </small>
                              </Card.Text>
                            </Col>
                            <Col className="">
                              <Card.Text>
                                <small>
                                  Category
                                  <span className="text-dark font-weight-bold">
                                    : {ele.category}
                                  </span>
                                </small>
                              </Card.Text>
                            </Col>
                          </Row>

                          <Row>
                          <Col>
                          <Card.Text>
                                <small>
                                  Location
                                  <span className="text-dark font-weight-bold">
                                    : {ele.location}
                                  </span>
                                </small>
                              </Card.Text>
                            </Col>
                            <Col>
                             
                              <Card.Text>
                                <small>
                                  Email
                                  <span className="text-dark font-weight-bold">
                                    : {ele.email}
                                  </span>
                                </small>
                              </Card.Text>
                            </Col>
                          
                            <Col>
                              <Container className="d-flex justify-content-end p-1 align-items-center">
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleAddModal();
                                    setJobId(ele.id);
                                    setSelected(ele);
                                  }}
                                  className="netZoneCardApplyBtn"
                                >
                                  Apply
                                </Button>
                              </Container>
                            </Col>
                          </Row>
                        </Col>

                        <ModalAdd
                          show={showAddModal}
                          onHide={() => setshowAddModal(false)}
                          onSubmit={handleAddSubmit_NetworkZone}
                          selectedData={selectedData}
                        />
                      </Container>
                    </Card.Body>
                  </Card>
                ))
              ) : (
                <Card className="mb-3 shadow border-3 rounded-3">
                  <Card.Body>
                    <Card.Text className="text-center h5">
                      No data is available
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-3">
        <Row>
          {/* <Col>
            <p className="text-muted font-weight-bold">{enteries}</p>
          </Col> */}
          <Col>
            <ResponsivePagination
              previousLabel="Prev"
              nextLabel="Next"
              extraClassName="justify-content-end align-items-center paginationClass"
              current={currentPage}
              total={totalPages}
              onPageChange={setCurrentPage}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default NetworkingZone;
