import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { Button, Row, Col, Container,Card } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFormGroup,EmailFormGroup,TextAreaFormGroup,SelectFormGroup,FileFormGroup } from "components/lib/formGroup";
import { useNavigate } from "react-router-dom";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { swalfirefunctionality } from "services/alertServices";
import { addNetworkZoneSchema } from "./validation";
import { useState } from "react";
import axios from "axios";
import { headers_image_upload } from "Helpers/FetchApiMethod";
import { apiURL } from "components/URLs/URL";

export default function AddNetworkZone(props) {
  const navigate = useNavigate();
const [category,setCategory]=useState([{"value":"Networking","label":"Networking"},{"value":"Partner","label":"Partner"},{"value":"Merger_Acquisition","label":"Merger & Acquisition"},{"value":"Resources","label":"Resources"},{"value":"Others","label":"Others"},])

const [firmImageName, setFirmImageName] = useState(undefined);
async function imageUploadResult(event) {
  const fileValue = event.target.files[0];
  if (fileValue.size <= 1024 * 1024) {
    let imageFileName = Date.now() + "_" + fileValue.name;
    let newIpFormData = new FormData();
    newIpFormData.append("folderName", "images");
    newIpFormData.append("file", fileValue, fileValue.name);
    newIpFormData.append(
      "imageName",
      imageFileName.replaceAll(" ", "").split(".")[0]
    );
    const uploadImageRespo = await axios.post(
      `${apiURL}image_upload`,
      newIpFormData,
      { headers: headers_image_upload }
    );
    try {
      if (uploadImageRespo.data != undefined) {
        if (uploadImageRespo.data.status == true) {
          setFirmImageName(uploadImageRespo.data.data);
          swalfirefunctionality(
            "Good Job",
            `${uploadImageRespo.data.message}`,
            "success"
          );
        } else {
          swalfirefunctionality("error", "Your image not uploaded", "error");
        }
      } else {
        
      }
    } catch (error) {
      swalfirefunctionality("error", `${error}`, "error");
    }
    
  } else {
    swalfirefunctionality("error", "Image should be less than 2 MB", "error");
  }
}

  const handleAddSubmit = (data) => {
    let reqData = data;
    reqData.image = firmImageName;
    reqData.created_by = "1";
    const add_net_working_api_result = FetchApiMethod(
      "addNetworkZone",
      reqData,
      "POST"
    );
    add_net_working_api_result.then((res) => {
      try {
        if (res.message === "Network zone created successfully") {
          swalfirefunctionality(
            `${res.data.position} is added successfully`,
            "Good Job",
            "success"
          );
          reset();
          navigate("/networkingZone");
        } else {
          swalfirefunctionality(`${res.message}`, "error", "error");
        }
      } catch (error) {
        swalfirefunctionality(`${error}`, "error", "error");
      }
    });
  };


  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(addNetworkZoneSchema),
  });


  return (
    <Container fluid className="p-0 mb-3">
      <Form
        aria-autocomplete="false"
        noValidate
        onSubmit={handleSubmit(handleAddSubmit)}
      >
        <Container className="p-3 mt-3">
          <Card className="border-3 rounded-3 shadow">
          <Card.Header className="p-0 h3 px-3 allMainHeading">
              Submit a post
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                <SelectFormGroup
                name="category"
                placeholder="Select..."
                label="Category"
                register={register}
                errors={errors}
                dirtyFields={dirtyFields}
                required="*"
                options={category}
                />
                </Col>
           
                <Col>
                  <TextFormGroup
                    name="name"
                    placeholder="Enter firm name..."
                    label="Firm Name"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <FileFormGroup
                    name="image"
                    label="Firm Logo"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    imageUpload={imageUploadResult}
                  />
                </Col>

                <Col>
                  <TextFormGroup
                    name="contact_number"
                    placeholder="Enter contact_number..."
                    label="Contact No."
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EmailFormGroup
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    name="email"
                    label="Email"
                    placeholder="Enter email..."
                  />
                </Col>
                <Col>
                  <TextFormGroup
                    name="location"
                    placeholder="Enter location..."
                    label="Location"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <TextFormGroup
                    name="position"
                    placeholder="Enter position..."
                    label="Position"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                  />
                </Col>
                <Col>
                  <TextFormGroup
                    name="total_vacancy"
                    placeholder="Enter total vacancy..."
                    label="Total Vacancy"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                  />
                </Col>
                <Col>
                  <TextAreaFormGroup
                    name="request"
                    placeholder="Enter postion details..."
                    label="Position Details"
                    register={register}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    required="*"
                    row={3}
                  />
                </Col>
              </Row>
              <Container className="p-0">
                <Row>
                  <Col className="text-right">
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Container>
      </Form>
    </Container>

  );
}
