import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Controller } from "react-hook-form";
import React, { useState, Component, useEffect } from "react";
import ReactSwitch from "react-switch";
import FetchApiMethod from '../../Helpers/FetchApiMethod'

export function EmailFormGroup({
  label,
  register,
  name,
  placeholder,
  errors,
  dirtyFields,
  required,
  defaultValue,
  minlength,
  maxlength,
  readonly,
  upperCase,
  optionType,
  setEmail,
  setgetDataCount,
  setBlockCount,
  onchange
}) {

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(value, 'line no 136');
    setValue(value);
  };

  const handlechangeEmail = (e) => {
    console.log(e, 'line no 23');
    const data = {
      "email": e.target.value,
      "postman": 'Y'
    }
    console.log(data, 'line no 27');
    setEmail(data)
    setgetDataCount(0)
    setBlockCount(0)
  }
  return (
    <Form.Group className="mb-0 my-1" controlId="formBasicEmail">
      <Form.Label>
        {label}
        <span style={{ color: "red" }}>{required}</span>
      </Form.Label>
      <Form.Control
        required
        {...register(name)}
        type="email"
        isValid={!errors.email && dirtyFields.email}
        isInvalid={!!errors.email}
        placeholder={placeholder}
        onChange={handleInputChange}
        value={value}
        onBlur={onchange == 'Y' ? handlechangeEmail : undefined}
      />
      <Form.Control.Feedback type="invalid">
        {errors.email?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function PasswordFormGroup(props) {
  const {
    register,
    errors,
    dirtyFields,
    label,
    name,
    correctPassword,
    onChange,
    required,
  } = props;
  return (
    <Form.Group className="mb-0">
      <Form.Label>
        {label}
        <span style={{ color: "red" }}>{required}</span>
      </Form.Label>
      <Form.Control
        required
        {...register(name)}
        type="password"
        isValid={!errors.password && dirtyFields.password}
        isInvalid={!!errors.password}
        placeholder={label}
        onChange={onChange}
        autoComplete="off"
      />
      <Form.Text muted>{label} must be minimum 6 characters long</Form.Text>
      <Form.Control.Feedback type="invalid">
        {errors.password?.message}
      </Form.Control.Feedback>
      <Alert show={correctPassword === false} variant="danger">
        Not matched
      </Alert>
    </Form.Group>
  );
}

export function TextFormGroup(props) {
  const {
    label,
    register,
    name,
    placeholder,
    errors,
    dirtyFields,
    required,
    defaultValue,
    minlength,
    maxlength,
    readonly,
    upperCase,
    optionType
  } = props;

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(value, 'line no 136');
    setValue(value);
  };

  const handleInputChangee = (e) => {
    handleInputChange(e)
  }


  const handlechange = (e) => {
    const numbersRegex = /^[0-9]*$/;
    if (numbersRegex.test(e.target.value) == false) {
      const numericInput = e.target.value.replace(/\D/g, '');
      e.target.value = numericInput;
    }
  }

  const keys = name.split(".");
  let errorMessage = errors;
  for (const key of keys) {
    if (key in errorMessage) errorMessage = errorMessage[key];
    else break;
  }
  console.log(defaultValue, 'line no 122');
  return (
    <Form.Group className="mb-0 my-1">
      {label && (
        <Form.Label>
          {label}
          <span style={{ color: "red" }}>{required}</span>
        </Form.Label>
      )}
      <Form.Control
        className={upperCase}
        required
        {...register(name)}
        type="text"
        isValid={!errors[name] && dirtyFields[name]}
        isInvalid={!!errors[name]}
        placeholder={placeholder}
        onChange={handleInputChangee}
        // defaultValue={defaultValue}
        value={value}
        minLength={minlength}
        maxLength={maxlength}
        readOnly={readonly}
        onInput={name === "phone" || name === "contact_number" || name === "contact_no" || name === "stipend_range" || name === "frn_no" || name == "membership_id" || name == "membership_no" ? optionType != 'CA Student' ? handlechange : undefined : undefined}
      />
      <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
        {errorMessage?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function TextAreaFormGroup(props) {
  const {
    label,
    register,
    name,
    placeholder,
    errors,
    dirtyFields,
    row,
    required,
    defaultValue,
    readonly,
  } = props;

  // Initialize state with defaultValue
  const [value, setValue] = useState(defaultValue);

  // Update the state if defaultValue changes
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  // Update value in state when input changes
  const handleInputChange = (e) => {
    console.log(e.target.value, 'line no 215');
    setValue(e.target.value);
  };

  console.log(defaultValue, 'line no 218');
  return (
    <Form.Group className="mb-0 my-1" controlId="formBasicTextArea">
      {label && (
        <Form.Label>
          {label}
          <span style={{ color: "red" }}>{required ? "*" : ""}</span>
        </Form.Label>
      )}
      <Form.Control
        required
        {...register(name)}
        as="textarea"
        rows={row ?? 1}
        isValid={!errors[name] && dirtyFields[name]}
        isInvalid={!!errors[name]}
        placeholder={placeholder}
        value={value} // Bind value to state
        onChange={handleInputChange} // Update state on change
        readOnly={readonly}
      />
      <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function NumberFormGroup(props) {
  const { label, register, name, placeholder, errors, dirtyFields, required } =
    props;

  return (
    <Form.Group className="mb-0" controlId="formBasicNumber">
      {label && (
        <Form.Label>
          {label}
          <span style={{ color: "red" }}>{required}</span>
        </Form.Label>
      )}
      <Form.Control
        required
        {...register(name)}
        type="number"
        isValid={!errors[name] && dirtyFields[name]}
        isInvalid={!!errors[name]}
        placeholder={placeholder}
      />
      <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function FileFormGroup(props) {
  const {
    label,
    register,
    name,
    placeholder,
    errors,
    dirtyFields,
    required,
    imageUpload,
  } = props;

  return (
    <Form.Group className="mb-0" controlId="formBasicFile">
      {label && (
        <Form.Label>
          {label}
          <span style={{ color: "red" }}>{required}</span>
        </Form.Label>
      )}
      <Form.Control
        required
        {...register(name)}
        type="file"
        isValid={!errors[name] && dirtyFields[name]}
        isInvalid={!!errors[name]}
        placeholder={placeholder}
        onChange={imageUpload}
      />
      <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function DateFormGroup(props) {
  const { label, register, name, placeholder, defaultValue, errors, dirtyFields, required } =
    props;
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(value, 'line no 136');
    setValue(value);
  };
  const [minStartData, setMinStartData] = useState("");
  const [minEndData, setMaxEndData] = useState("");
  const today = new Date();
  const formattedToday = today.toISOString().split('T')[0];
  const dateHandler = (event) => {
    name == "end_date"
      ? setMinStartData(event.target.value)
      : setMaxEndData(event.target.value);
  };
  let currentDateDOB = new Date();
  currentDateDOB.setFullYear(currentDateDOB.getFullYear() - 18);
  let eighteenYearsAgo = currentDateDOB.toISOString().split("T")[0];
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  let yesterday = currentDate.toISOString().split("T")[0];
  return (
    <Form.Group className="mb-0 my-1" controlId="formBasicDate">
      {label && (
        <Form.Label>
          {label}
          <span style={{ color: "red" }}>{required}</span>
        </Form.Label>
      )}
      <Form.Control
        required
        {...register(name)}
        type="date"
        isValid={!errors[name] && dirtyFields[name]}
        isInvalid={!!errors[name]}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        max={name == "dob" ? eighteenYearsAgo : name == "qual_date" ? yesterday : ''}
      // onChange= {dateHandler}
      // min={name == "end_date" ? minEndData : ""}
      // max={name == "start_date" ? minStartData : ""}
      />
      <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
export function DateAgeFormGroup(props) {
  let currentDate = new Date().toISOString().split("T")[0];
  let spiltYear = currentDate.split("-")[0] - 18;
  let validYear =
    spiltYear +
    "-" +
    currentDate.split("-")[1] +
    "-" +
    currentDate.split("-")[2];

  const { label, register, name, placeholder, errors, dirtyFields, required } =
    props;

  return (
    <Form.Group className="mb-0" controlId="formBasicDateAge">
      {label && (
        <Form.Label>
          {label}
          <span style={{ color: "red" }}>{required}</span>
        </Form.Label>
      )}
      <Form.Control
        required
        {...register(name)}
        type="date"
        isValid={!errors[name] && dirtyFields[name]}
        isInvalid={!!errors[name]}
        placeholder={placeholder}
        max={validYear}
      />
      <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function CheckFormGroup(props) {
  const { name, register, label, errors, dirtyFields } = props;
  return (
    <Form.Group className="mb-0" controlId="formBasicCheckbox">
      <Form.Check
        type="checkbox"
        required
        {...register(name)}
        label={label}
        isValid={!errors[name] && dirtyFields[name]}
        isInvalid={!!errors[name]}
      />
      <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function SearchBox(props) {
  const { placeholder, type, onSearch } = props;
  return (
    <Form.Control
      className="border border-primary"
      type={type}
      placeholder={placeholder}
      onChange={onSearch}
    />
  );
}

export function RadioFormGroup(props) {
  const { name, register, label, errors, dirtyFields, value } = props;
  return (
    <Form.Group className="mb-0" controlId="formBasicRadiobox">
      <Form.Check
        type="radio"
        required
        {...register(name)}
        label={label}
        isValid={!errors[name] && dirtyFields[name]}
        isInvalid={!!errors[name]}
        value={value}
      />
      <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function SelectFormGroup(props) {
  const {
    label,
    register,
    name,
    placeholder,
    errors,
    dirtyFields,
    required,
    options,
    handleInputChange
  } = props;
  return (
    <Form.Group className="mb-0" controlId={`formBasic${name}`}>
      <Form.Label>
        {label}
        {required && <span style={{ color: "red" }}> *</span>}
      </Form.Label>
      <Form.Control
        as="select"
        onChange={handleInputChange}
        name={name}
        {...register(name)} // Assuming you're using RHF
        isValid={!errors[name]}
        isInvalid={!!errors[name]}
      >
        <option value="">{placeholder || "Select"}</option>
        {options?.map((ele, index) => (
          <option key={`select_${index}`} value={ele.value}>
            {ele.label}
          </option>
        ))}
      </Form.Control>

      <Form.Control.Feedback type="invalid" style={{ color: "red" }}>
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function ToggleSwitch(props) {
  const { handleChangeToggle, statusChecked } = props;
  return <ReactSwitch
    className="mx-2"
    onChange={handleChangeToggle}
    checked={statusChecked}
  />;
}
