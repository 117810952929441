import React from "react";
import { Row, Col, NavbarBrand, UncontrolledTooltip}  from "reactstrap";
// import RoundedImage from "../ImagesSection/RoundedImg";
import ButtonIcon from "../ButtonsSection/ButtonIcon";
import { useMediaQuery } from 'react-responsive';
import { Link } from "react-router-dom";

// import favicon from "src/assets/img/brand/favicon.png";
// componentDidMount() {
//     let headroom = new Headroom(document.getElementById("navbar-main"));
//     // initialise
//     headroom.init();
//   };
//   state = {
//     collapseClasses: "",
//     collapseOpen: false,
//   };

//   onExiting = () => {
//     this.setState({
//       collapseClasses: "collapsing-out",
//     });
//   };

//   onExited = () => {
//     this.setState({
//       collapseClasses: "",
//     });
//   };

function HeroHeader(){
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    return(
        <>
            <header  className="mt-1 header-global navbar-horizontal navbar-expand navbar-dark flex-row ">
            {/* <Navbar
            className="headroom" id="navbar-home" expand="lg"> */}
                <Row className="justify-content-around px-3 align-items-center">
                    <Col lg="1" className="text-left p-1">
                    <NavbarBrand className="d-flex align-items-center justify-content-between m-0"  to="/" tag={Link} >
                        <img 
                            alt="ICAI Logo"
                            className="img-fluid"
                            src={require("assets/img/theme/ICAI-Logo.png")} 
                        />
                        {isDesktopOrLaptop == true ? '' :   <img 
                            alt="75th Logo"
                            className="img-fluid"
                            src={require("assets/img/theme/75th-Logo.png")} 
                        />}
                      
                    </NavbarBrand>
                    </Col>
                    <Col lg="6" className="text-center">
                        <p className="heading-title text-default text-uppercase mb-0 imageButton allSubHeading pt-2" style={{ lineHeight: '1.4rem', fontSize: '23px' }}>
                            The Institute of Chartered Accountants of India
                        </p>

                        <p className="mb-0 text-default imageButton" style={{ lineHeight: '-0.06rem' }}>(Set up by an Act of Parliament)</p>
                        <p className="lead text-default m-0 imageButton" style={{ lineHeight: '2rem' }}><strong>GAUTAM BUDH NAGAR BRANCH (CIRC) </strong></p>
                    </Col>

                    <Col lg="1" className="text-left p-1">
                    <NavbarBrand className="m-0 imageNone2" to="/" tag={Link} >
                        
                    {isDesktopOrLaptop == true ? <img 
                        alt="..." className="img-fluid"
                        src={require("assets/img/theme/75th-Logo.png")} 
                        /> : ''} 
                    </NavbarBrand>
                    </Col>


                    {/* <Col lg="3" className="socileIconRight imageButton">
                   <ButtonIcon className="btn-primary" id="tooltipfb" href={`javascript:void(window.open('https://www.facebook.com/profile.php?id=100090774616111', '_blank'))`} >
                        <i className="fa fa-facebook-square" />
                    </ButtonIcon>
                            <UncontrolledTooltip delay={0} placement="bottom" target="tooltipfb" trigger="hover focus" > Like Us </UncontrolledTooltip>

                    <ButtonIcon className="btn-danger" id="tooltipInsta" href={`javascript:void(window.open('https://www.instagram.com/icaigbnbranch/', '_blank'))`} >
                            <i className="fa fa-instagram" />    </ButtonIcon>
                            <UncontrolledTooltip delay={0} placement="bottom" target="tooltipInsta" trigger="hover focus" > Follow Us </UncontrolledTooltip>
                    <ButtonIcon className="btn-default" id="tooltipIn" href={`javascript:void(window.open('https://www.linkedin.com/in/ICAIGBN/', '_blank'))`}>
                            <i className="fa fa-linkedin-square" />    </ButtonIcon>
                            <UncontrolledTooltip delay={0} placement="bottom" target="tooltipIn" trigger="hover focus" > Connect with Us </UncontrolledTooltip>
                    </Col> */}

                </Row>
                {/* </Navbar> */}
            </header>
        </>
    );
}
export default HeroHeader;