import React from "react";
import FetchApiMethod from "../../../Helpers/FetchApiMethod";
import { useEffect, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


function TransactionStatusForm() {
  const [getPaymentStats, setPaymentStats] = useState({ "tid": '', "rid": '', "status": "", "comments": '', "email": '', "amount": '' })
  const getpaymentstats = (id, type) => {
    const data = {
      'order_id': id,
      "type": type,
      'postman': 'Y'
    }
    const orderconfirmationrazorpayget = FetchApiMethod("membership/get_attendee_pay_response", data, "POST");
    orderconfirmationrazorpayget.then((respo) => {
      console.log(respo.length, 'line no 292');
      if (respo.length > 0) {
        setPaymentStats({ "tid": respo[0].ipgTransactionId, "rid": respo[0].oid, "status": respo[0].status, "comments": respo[0].comments, "email": respo[0].email, "amount": respo[0].chargetotal })
      }
    })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    const type = searchParams.get('type');
    getpaymentstats(id, type)
  }, []);

  return (
    <Container fluid className="p-0 mb-3">

      <Container className="p-3 mt-3">
        <Card className="border-3 rounded-3 shadow">
          <CardHeader className="">
            <h3 class="ml-3 mb-0 allMainHeading"> Transaction Status</h3>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            {/* <div className="text-center text-muted mb-4">
                        <small>Or sign up with credentials</small>
                      </div> */}



            <Form role="form">
              <Row>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="TransactionId">
                      <i className="ni ni-single-02" /> Transaction Id
                    </Label>
                    <Input value={getPaymentStats.tid} id="TransactionId" placeholder="Transaction Id" type="text" />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="ReferenceId">
                      <i className="ni ni-badge" /> Reference Id
                    </Label>
                    <Input value={getPaymentStats.rid} id="ReferenceId" placeholder="Reference Id" type="text" />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="Status">
                      <i className="ni ni-chart-bar-32" /> Status
                    </Label>
                    <Input value={getPaymentStats.status} id="Status" placeholder="Status" type="text" />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="Comments">
                      <i className="fa fa-comments" /> Comments
                    </Label>
                    <Input value={getPaymentStats.comments} id="Comments" placeholder="Comments" type="text" />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="email">
                      <i className="ni ni-email-83" /> Email
                    </Label>
                    <Input value={getPaymentStats.email} id="email" placeholder="Email" type="email" />
                  </FormGroup>
                </Col>

                <Col lg={6}>
                  <FormGroup>
                    <Label for="RegistrationOff">
                      <i className="fa fa-gift" /> Registration Offers
                    </Label>
                    <Input value={getPaymentStats.reg_offer} id="RegistrationOff" placeholder="Registration Offers" type="text" />
                  </FormGroup>
                </Col>

                <Col lg={6}>
                  <FormGroup>
                    <Label for="TotalAmt">
                      <i className="fa fa-money" /> Total Amount
                    </Label>
                    <Input
                      id="TotalAmt"
                      placeholder="Total Amount"
                      type="text"
                      autoComplete="off"
                      value={getPaymentStats.amount}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="text-center">
                <Button className="mt-4" color="success" type="button">
                  <a href="/">Back to Home</a>
                </Button>
              </div>
            </Form>

          </CardBody>
        </Card>
      </Container>
      {/* </Form> */}
    </Container>
  );
}

export default TransactionStatusForm;
