import React from "react";
import { Spinner } from "reactstrap";
import "../../app.css";
// import "./Loading.css";

const Loading = (props) => {
  return (
    <div className={`loading-container ${props.loader == false ? 'd-none' : ''}`}>
      <Spinner color="primary" />
      <p className="loading-text">Loading...</p>
    </div>
  );
};

export default Loading;