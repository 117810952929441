import React, { useState } from 'react';
import './ContactForm.css';
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from 'react-icons/fa';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://icaigbnapi.proeffico.com/api/addContactUs?postman=Y', {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
        },
        body: JSON.stringify({
          contact_number: phone,
          email_id: email,
          query: message,
          name: name,
        }),
      });

      if (response.ok) {
        console.log('Message sent successfully');
        setShowPopup(true);
        // Reset form fields 
        setName('');
        setEmail('');
        setMessage('');
        setPhone('');

        // Hide popup after 3 seconds
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      } else {
        console.error('Failed to send message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <section style={{ backgroundColor: "white" }}>
      <div className="section-header">
        <div className="container">
          <h1 className="px-3 display-2 allMainHeading">Contact Us</h1>
          {/* <h2>Contact Us</h2> */}
          <p style={{color: "#5e72e4"}}>If you have any questions, please don't hesitate to send us a message.</p>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="contact-info">
            <div className="contact-info-item">
              <div className="contact-info-icon">
                <a href="https://maps.app.goo.gl/SU4g246YX4Fcu78f7" target="_blank">
                <FaMapMarkerAlt size="2em" className="text-white floating floatingSize pb-2 mt-3"/></a>
              </div>
              <div className="contact-info-content">
                <h4>Address</h4>
                <p><a href="https://maps.app.goo.gl/SU4g246YX4Fcu78f7" target="_blank">Gautam Budh Nagar Branch of Central India,<br /> Regional Council of the Institute of, <br /> Chartered Accountants of India</a></p>
              </div>
            </div>

            <div className="contact-info-item" style={{marginLeft: "0%"}}>
              <div className="contact-info-icon">
                <a href="tel:+0120-4280419" target="_blank" rel="noopener noreferrer">
                <FaPhone size="2em" className="text-white floating floatingSize pb-2 mt-3"/></a>
              </div>
              <div className="contact-info-content">
                <h4>Phone</h4>
                <p><a href="tel:+0120-4280419" target="_blank" rel="noopener noreferrer">+0120-4280419</a></p>
              </div>
            </div>
            <div className="contact-info-item" style={{marginLeft: "0%"}}>
              <div className="contact-info-icon">
                <a href="mailto:infoicainoida@gmail.com" target="_blank" rel="noopener noreferrer">
                <FaEnvelope size="2em" className="text-white floating floatingSize pb-2 mt-3"/></a>
              </div>
              <div className="contact-info-content">
                <h4>Email</h4>
                <p><a href="mailto:infoicainoida@gmail.com" target="_blank" rel="noopener noreferrer">infoicainoida@gmail.com</a></p>
              </div>
            </div>
          </div>
          <div className="contact-form">
            <form onSubmit={handleSubmit} id="contact-form">
              <h2 className='allMainHeading'>Send Message</h2>
              <div className="input-box">
                <input class="text-capitalize"
                  type="text"
                  required
                  name="name"
                  value={name}onChange={(e) => setName(e.target.value)}
                  pattern="[A-Za-z\s]+"
                  title="Please enter only letters and spaces"
                  placeholder="Full Name"
                />
              </div>
              <div className="input-box">
                <input
                  type="email"
                  required
                  name="email"
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </div>
              <div className="input-box">
               <input
                  type="tel"
                  required
                  name="phone"
                  value={phone} 
                  onChange={(e) => setPhone(e.target.value)}
                  minLength="10"
                  maxLength="10"
                  pattern="[0-9]{10}"
                  title="Please enter a valid 10-digit phone number"
                  placeholder="Phone"
                />
              </div>
              <div className="input-box">
                <textarea class="text-capitalize"
                  required
                  name="message"
                  value={message} 
                  onChange={(e) => setMessage(e.target.value)}
                  minLength="5"
                  maxLength="250"
                  placeholder="Type your Message..."
                ></textarea>
              </div>
              <div className="input-box">
                <input type="submit" value="Send" name="submit" className="rounded"/>
              </div>
              {showPopup && (
                <div className="popup">
                  <p className='text-green text-center'>Your response was submitted successfully</p>
                  {/* <button onClick={() => setShowPopup(false)}>Close</button> */}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      {/* Embedding Google Maps iframe */}
      <div className="map-container">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56041.37072361987!2d77.29237305820315!3d28.612204100000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce56997aaaaab%3A0xd4d82ee2dd81216c!2sICAI%20GAUTAM%20BUDH%20NAGAR%20BRANCH%20OF%20(CIRC)!5e0!3m2!1sen!2sin!4v1715589876334!5m2!1sen!2sin" 
          width="100%" 
          height="450" 
          style={{ border: 0 }} 
          allowFullScreen="" 
          loading="lazy" 
          title="Google Map"
        ></iframe>
      </div>
    </section>
  );
};

export default ContactForm;
