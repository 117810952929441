import React, { useState, useEffect } from "react";
// import HeroHeader from "views/IndexCompnents/HeaderSection/HeroHeader";
// import IcaiNavBar from "components/Navbars/IcaiNavBar";
import IcaiCarousel from "components/HomeComponents/IcaiCarousel";
import EventCards from "components/HomeComponents/EventCards";
import Chairman from "components/HomeComponents/ChairmanMsg";
import NoticeTab from "components/HomeComponents/NoticeTab";
import TeamSection from "components/HomeComponents/OfficeBearer";
// import MyFooter from "components/Footers/IcaiFooter.js";
import PublicationCards from "components/HomeComponents/Publication";
import IcaiResources from "components/HomeComponents/IcaiResources";
// import HomeVideo from "components/HomeComponents/HomeVideoBanner";


const Home = () => {
const [banners, setBanners] = useState([]);
const [events, setEvents] = useState([]);
const [chairmanmsg, setChairmanMsg] = useState([]);
const [officebearer, setOfficeBearer] = useState([]);
const [publication, setPublication] = useState([]);
const [notification, setNotification] = useState([]);
const [countercard, setCounter] = useState([]);


  const getallevent = async () => {
    const apihandler =
    "https://icaigbnapi.proeffico.com/api/event/getEvents?postman=Y&status=Active";
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
      },
    };
    try {
        const apihandlerres = await fetch(apihandler, settings);
        const respodata = await apihandlerres.json();
        // Assuming respodata is an array of events
        console.log(respodata, "res");
        const fdata = respodata.data
        setEvents(fdata?.map((i) => i));
        } catch (error) {
        console.error("Error fetching events:", error);
        }
    };

    const fetchBanners = async () => {
      try {
        const apiUrl = "https://icaigbnapi.proeffico.com/api/getBannerswebsite?postman=Y&banner_type=homepage";
        const settings = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
          },
        };
        const response = await fetch(apiUrl, settings);
        const data = await response.json();
        const fdata = data.data
        setBanners(fdata?.map((i) => i));


        // console.log(data.data);
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    const fetchOfficeBearer = async () => {
      try {
        const apiUrl = "https://icaigbnapi.proeffico.com/api/getChairmanOfficeBearersnew?postman=Y&display_y=Y";
        const settings = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
          },
        };
        const response = await fetch(apiUrl, settings);
        const data = await response.json();
        const fdata = data.data
        setOfficeBearer(fdata?.map((i) => i));
      } catch (error) {
        console.error("Error fetching office bearers:", error);
      }
    };
  
    const fetchChairmanMsg = async () => {
      try {
        // const apiUrl = `https://icaigbnapi.proeffico.com/api/getChairmanOfficeBearers?postman=Y&id={62}`;
        const apiUrl1 = `https://icaigbnapi.proeffico.com/api/getChairma?postman=Y`;
        const settings1 = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
          },
        };
        const response1 = await fetch(apiUrl1, settings1);
        const data1 = await response1.json();
        const fdata = data1.data
        setChairmanMsg(fdata?.map((i) => i));
      } catch (error) {
        console.error("Error fetching office bearers:", error);
      }
    };
  
    const fetchPublication = async () => {
      try {
        const apiUrl3 = "https://icaigbnapi.proeffico.com/api/event/getPublicationsnewsletter?postman=Y";
        const settings3 = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
          },
        };
        const response3 = await fetch(apiUrl3, settings3);
        const dataPub = await response3.json();
        const fdata = dataPub.data
        setPublication(fdata?.map((i) => i));
      } catch (error) {
        console.error("Error fetching publication and newsletter:", error);
      }
    };
 
    const fetchNotification = async () => {
      try {
        const apiUrl4 = "https://icaigbnapi.proeffico.com/api/getNoticeboardwebsite?postman=Y";
        const settings4 = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: 
              "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
          },
        };
        const response4 = await fetch(apiUrl4, settings4);
        const dataNoti = await response4.json();
        const fdata = dataNoti.data
        setNotification(fdata?.map((i) => i));
      } catch (error) {
        console.error("Error fetching Notification:", error);
      }
    };
  
    const fetchCounter = async () => {
      try {
        const apiUrl5 = "https://icaigbnapi.proeffico.com/api/NoticeCount";
        const settings5 = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: 
              "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
          },
        };
        const response5 = await fetch(apiUrl5, settings5);
        const data = await response5.json();
        const respodata = data
        // setCounter(fdata?.map((i) => i));
        setCounter(respodata.data);
        // console.log(respodata, 'counterdata');
      } catch (error) {
        console.error("Error fetching CounterNum:", error);
      }
    };
  

    useEffect(() => {
        getallevent();
        fetchBanners();
        fetchPublication();
        fetchOfficeBearer();
        fetchChairmanMsg();
        fetchNotification();
        fetchCounter();
    }, []);

    console.log(events,'line no 36');
    return (
        <>
            {/* <HeroHeader/>
            <IcaiNavBar/> */}
            <IcaiCarousel banners={banners} />
            {/* <HomeVideo/> */}
            <EventCards eventsData={events} />
            <Chairman ChairmanMsg={chairmanmsg} />
            <NoticeTab Notification={notification} counterCard={countercard} />
            <TeamSection officeBearer={officebearer} />
            <PublicationCards publicationData={publication} />
            <IcaiResources/>
            {/* <MyFooter/> */}
        </>
    );
};

export default Home;