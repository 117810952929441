import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ToggleSwitch } from "components/lib/formGroup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "react-bootstrap";
import MemberShipForm from "./MemberShipForm";
import { memberIdSchema } from "./validation";
import Non_MemberShip_Form from "./Non_MemberShip_Form";
import { Link } from "react-router-dom";
import { encrytedString } from "Helpers/encrytedStr";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { swalfirefunctionality } from "services/alertServices";
import { debounce } from "Helpers/Debounce";
import useRazorpay from "react-razorpay";
import { confirmationAlertServices } from "services/alertServices";

import {Card,CardBody,CardText,Row,Col,Container,Button,} from "reactstrap";
import { TextFormGroup, TextAreaFormGroup } from "components/lib/formGroup";
import { AlertServices } from "services/alertServices";

function MemeberIdForm(props) {
const [Razorpay] = useRazorpay();
const eventPrice=parseInt(props.price);
  // const [memberIdForNonMember, setMemberIdForNonMember] = useState(null);
  // const [isAnnual_yn, setAnnual_yn] = useState(null);
  // const handlerGetMemberNo = (event) => {
  //   const { name, value } = event.target;
  //   setMemberIdForNonMember(value);
  // };
  // const debounceGetMemberId = debounce(handlerGetMemberNo);

  const {membership_no,name,organisation_address,organisation_gstin,organisation_name,phone,pincode,state,email,city,} = props.memberData ?? [];
  const memberShipFormArr = [
    {
      id: 1,
      component: TextFormGroup,
      ref: "Membership No.",
      name: "membership_no",
      type: "text",
      required: "*",
      hide: "N",
      defaultValue: membership_no,
      readonly: props.toggleCheck ? "readonly" : "" ,
      // func1:props.toggleCheck ? "":debounceGetMemberId,
    },
    {
      id: 2,
      component: TextFormGroup,
      ref: "Name",
      name: "name",
      type: "text",
      required: "*",
      hide: "N",
      defaultValue: name,
      readonly: props.toggleCheck ? "readonly" : "" ,
    },
    {
      id: 3,
      component: TextFormGroup,
      ref: "Organisation Name",
      name: "organisation",
      type: "text",
      required: "*",
      readonly: props.toggleCheck ? "readonly" : "" ,
      hide: "N",
      defaultValue: organisation_name,
    },
    {
      id: 4,
      component: TextFormGroup,
      ref: "Email",
      name: "email",
      type: "text",
      required: "*",
      hide: "N",
      defaultValue: encrytedString(email, "email"),
      readonly: props.toggleCheck ? "readonly" : "",
    },
    {
      id: 5,
      component: TextFormGroup,
      ref: "Contact Number",
      name: "phone",
      required: "Y",
      required: "*",
      maxlength: "10",
      minlength: "10",
      hide: "N",
      defaultValue: encrytedString(phone, "phone"),
      readonly: props.toggleCheck ? "readonly" : "" ,
    },
    {
      id: 6,
      component: TextFormGroup,
      ref: "GST No.",
      name: "organisation_gstin",
      type: "text",
      maxlength: "15",
      minlength: "15",
      hide: "N",
      defaultValue: organisation_gstin,
    },
  ];
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(memberIdSchema),
  });

  const handlepayment = () => {
    props.setLoader(true);
    if(eventPrice>0){
    const data = {
      "event_id": props.eventID,
      "member_id": props.payUserDetails.membership_no,
      "price": eventPrice,
      "email": props.payUserDetails.email_id,
      "name": props.payUserDetails.name,
      "contact_no": props.payUserDetails.contact_no
    }
    const savemembertrancres = FetchApiMethod("addEventMetadata", data, "POST");
    savemembertrancres.then((restre) => {
      if (restre.status == true ) {
        const orderdata = {
          "amount": parseInt((eventPrice + (eventPrice * 0.18))*100),
          "currency": 'INR',
          "receipt": restre.data.uuid,
          "notes": {
            "notes_key_1": "ICAI Payment",
          }
        }
        const createorderforRzpres = FetchApiMethod("membership/razorpay", orderdata, "POST");
        createorderforRzpres.then((res) => {
          if (res.status = "true") {
            const options = {
              key: "rzp_live_9GsS48OEtXOB2m",
              amount: parseInt((eventPrice + (eventPrice * 0.18))*100), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: "INR",
              name: "ICAI",
              description: "Live Transaction",
              image: "/static/media/ICAI-Logo.f3fcefd6001e294a1682.png",
              order_id: res.data.id,  
              handler: function (response) {
                const sdata = {
                  "total_value": "Y",
                  "txndate_processed": '',
                  "ccbin": "NA",
                  "oid": res.data.id,
                  "cccountry": "",
                  "expmonth": "NA",
                  "merchantTransactionId": restre.data.uuid,
                  "endpointTransactionId": "NA",
                  "currency": 'INR',
                  "processor_response_code": "NA",
                  "chargetotal": parseInt(eventPrice + (eventPrice * 0.18)),
                  "terminal_id": "Web",
                  "approval_code": "NA",
                  "expyear": "NA",
                  "response_code_3dsecure": "NA",
                  "notification_hash": "NA",
                  "schemeTransactionId": "NA",
                  "tdate": '',
                  "installments_interest": "NA",
                  "bname": "NA",
                  "ccbrand": "NA",
                  "refnumber": "NA",
                  "txntype": "Sale",
                  "paymentMethod": "E",
                  "txndatetime": '',
                  "cardnumber": "NA",
                  "ipgTransactionId": response.razorpay_payment_id,
                  "status": "APPROVED",
                  "comments": "ICAI Payment",
                  "ent_on": '',
                  "json": JSON.stringify(response),
                  "fail_reason": "NA",
                }
                const orderconfirmationrazorpay = FetchApiMethod("attendee_pay_response", sdata, "POST");
                orderconfirmationrazorpay.then((resto) => {
                  if (resto.message == 'success!') {
                    const data = {
                      'type' : "E",
                      'order_id': restre.data.uuid,
                      'postman': 'Y'
                    }
                    const orderconfirmationrazorpayget = FetchApiMethod("membership/get_attendee_pay_response", data, "POST");
                    orderconfirmationrazorpayget.then((respo) => {
                      console.log(respo.length, 'line no 292');
                      if (respo.length > 0) {
                        props.setLoader(false)
                        swalfirefunctionality('Successfully Registered', "success", "success", true,`/Transaction-Status?id=${restre.data.uuid}&type=E`)
                      } else {
                        props.setLoader(false)
                        swalfirefunctionality(`Something went wrong`, "error", "error", true,`/Transaction-Status?id=${restre.data.uuid}&type=E`)
                      }
                    })
                  }else{
                    props.setLoader(false)
                  }
                })
              },
              prefill: {
                name: props.payUserDetails.name,
                email: props.payUserDetails.email_id,
                contact: props.payUserDetails.contact_no,
              },
              theme: {
                color: '#0d0d65',
              },
            };
            const razorpay = new Razorpay(options);
            razorpay.on("payment.failed", function (response) {
              console.log(response);
              props.setLoader(false)
              const sdata = {
                "total_value": "Y",
                "txndate_processed": '',
                "ccbin": "NA",
                "oid": res.data.id,
                "cccountry": "",
                "expmonth": "NA",
                "merchantTransactionId": restre.data.uuid,
                "endpointTransactionId": "NA",
                "currency": 'INR',
                "processor_response_code": "NA",
                "chargetotal": parseInt(eventPrice + (eventPrice * 0.18)),
                "terminal_id": "Web",
                "approval_code": "NA",
                "expyear": "NA",
                "response_code_3dsecure": "NA",
                "notification_hash": "NA",
                "schemeTransactionId": "NA",
                "tdate": '',
                "installments_interest": "NA",
                "bname": "NA",
                "ccbrand": "NA",
                "refnumber": "NA",
                "txntype": "Sale",
                "paymentMethod": "E",
                "txndatetime": '',
                "cardnumber": "NA",
                "ipgTransactionId": response.razorpay_payment_id,
                "status": "DECLINED",
                "comments": "ICAI Payment",
                "ent_on": '',
                "json": JSON.stringify(response),
                "fail_reason": "NA",
              }
              const orderconfirmationrazorpaydec = FetchApiMethod("attendee_pay_response", sdata, "POST");
              orderconfirmationrazorpaydec.then((resto) => {
                if (resto.message == 'success!') {
                  const data = {
                    'order_id': restre.data.uuid,
                    'postman': 'Y'
                  }
                  const orderconfirmationrazorpayget = FetchApiMethod("membership/get_attendee_pay_response", data, "POST");
                  orderconfirmationrazorpayget.then((respo) => {
                    console.log(respo.length, 'line no 359');
                    if (respo.length > 0) {
                      props.setLoader(false)
                      swalfirefunctionality('Successfully Registered', "success", "success")
                    } else {
                      props.setLoader(false)
                      swalfirefunctionality(`Something went wrong`, "error", "error")
                    }
                  })
                }else{
                  props.setLoader(false);
                }
              })
            });
            razorpay.open();
            props.setLoader(false);
          } else {
            props.setLoader(false)
            swalfirefunctionality(`error`, res.message, "error");
          }
        })
      } else {
        props.setLoader(false)
        swalfirefunctionality(`error`, restre.message, "error");
      }
    })
  }else{
    swalfirefunctionality(``, `Amount should be greater than ${eventPrice}`, "warning");
    props.setLoader(false);
  }
  }
  return (
    <Container fluid className="p-0 mx-3">
      <Card>
        <CardBody className="p-2">
          <Row className="p-0 mt-1 justify-content-start m-1">
            <Col className="p-0">
              <Container className="d-flex align-items-center p-0 mx-0">
                <b
                  style={{ fontFamily: "", fontSize: "13px" }}
                  className="text-nowrap mb-0"
                >
                  Are you an annual Member
                </b>
                <Container>
                  <Form.Check
                    inline
                    label="Yes"
                    name="memberRadio"
                    type="radio"
                    defaultValue="annual_member"
                    onClick={props.handleToggleBtn}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="memberRadio"
                    type="radio"
                    defaultValue="non_annual_member"
                    onClick={props.handleRadioFlag}
                  />
                </Container>
              </Container>
            </Col>
          </Row>

          {props.memberStatus && (
            <Form noValidate onSubmit={handleSubmit(props.handleSubmit)}>
              <Container className="p-0 mb-2 mt-3 align-items-center d-flex mx-0 flex-wrap">
                <b style={{ fontSize: "13px" }} className="mr-2">
                  Please enter your membership no.
                </b>
                <TextFormGroup
                  name="membership_id"
                  placeholder="Enter member no..."
                  register={register}
                  errors={errors}
                  dirtyFields={dirtyFields}
                  required="*"
                  handleInputChange={props.handleInputChange}
                />
                <Button variant="primary" className="btn-md mx-2" type="submit">
                  Verify
                </Button>
                {props.is_Annual_yn === "N" && (
                  <Link to="/ICAI-Registration-From" className="mx-2">
                    <Button className=" btn-md align-item-end">
                      REGISTER FOR ANNUAL MEMBERSHIP
                    </Button>
                  </Link>
                )}
              </Container>
            </Form>
          )}
        </CardBody>
      </Card>

      {props.showForm && (
        <Card>
          <CardBody className="p-2">
            <MemberShipForm
              memberData={props.memberData}
              memberFee={props.memberFee}
              nonMemberFee={props.nonMemberFee}
              toggleBtnFlag={props.toggleCheck}
              is_Annual_yn={props.is_Annual_yn}
              handleAddSubmit={props.handleAddEventRegistrSubmit}
              selected_event={props.url_event_id}
              memberShipFormArr={memberShipFormArr}
              handlepayment={handlepayment}
              loader={props.loader}
              paymentStats={props.paymentStats}
              payUserDetails={props.payUserDetails}
              eventID={props.eventID}
              eventPrice={props.price}
            />
          </CardBody>
        </Card>
      )}

      {props.isChecked && !props.toggleCheck && (
        <Card>
          <CardBody className="p-2">
            <Non_MemberShip_Form
              memberFee={props.memberFee}
              nonMemberFee={props.nonMemberFee}
              toggleBtnFlag={props.toggleCheck}
              // is_Annual_yn={isAnnual_yn}
              handleAddSubmit={props.handleAddEventRegistrSubmit}
              selected_event={props.url_event_id}
              // non_memberShipFormArr={memberShipFormArr}
              payUserDetails={props.payUserDetails}
              paymentStats={props.paymentStats}
              eventID={props.eventID}
              handlepayment={handlepayment}
              loader={props.loader}
              setMemberData={props.setMemberData}
              eventPrice={props.price}
            />
          </CardBody>
        </Card>
      )}
    </Container>
  );
}

export default MemeberIdForm;
