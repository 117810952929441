import React, { useState, useEffect } from "react";
import HeroHeader from "views/IndexCompnents/HeaderSection/HeroHeader";
import IcaiNavBar from "components/Navbars/IcaiNavBar";
import MyFooter from "components/Footers/IcaiFooter.js";
import PastEventscomp from "components/PastEventsComponents/PastEventsComp.js";
import ResponsivePagination from "react-responsive-pagination";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { swalfirefunctionality } from "services/alertServices";
import { Container,Row,Col } from "react-bootstrap";

const PastEvents = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [perPage, setPerPage] = useState(12);
  const [enteries, setEnteries] = useState(null);
  const [events, setEvents] = useState([]);
 
  const getallevent = async () => {
    let paginationData = {
      pagination: {
        pagesize: perPage,
        pagenumber: currentPage,
      },
    };
    const getEventsApiResult = FetchApiMethod(
      "getEventswebsite?postman=Y&status=Disable",
      paginationData,
      "POST"
    );
    getEventsApiResult.then((res) => {
      try {
        if (res.status && res.message == "success") {
          console.log(res, "getevent");
          setEvents(res.data);
          setTotalPages(res.last_page);
          setCurrentPage(res.current_page);
          setPerPage(res.per_page);
          setEnteries(res.line);
        } else {
          swalfirefunctionality("error", res.message, "error");
        }
      } catch (error) {
        swalfirefunctionality("error", error, "error");
      }
    }); 
  };

  useEffect(() => {
    getallevent();
  }, [currentPage, perPage]);

  return (
    <div>
      <PastEventscomp eventsData={events} />
      <Container fluid className="p-3">
        <Row>
          {/* <Col>
            <p className="text-muted font-weight-bold">{enteries}</p>
          </Col> */}
          <Col>
            <ResponsivePagination
              previousLabel="Prev"
              nextLabel="Next"
              extraClassName="justify-content-end align-items-center paginationClass"
              current={currentPage}
              total={totalPages}
              onPageChange={setCurrentPage}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PastEvents;
