import React, { useEffect, useState } from "react";
// import { Container } from "react-bootstrap";
import DownloadPdf from "components/MaterialDownload/DownloadPdf";

const DownloadResources= () => {
    const [materialdata, setDownloadMaterialData] = useState();

      const fetchDownloadmaterialData = async () => {
        try {
          const apiUrl = "https://icaigbnapi.proeffico.com/api/membership/getDownloadmaterial?postman=Y";
          const settings = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
            },
          };
          const response = await fetch(apiUrl, settings);
        //   const data = await response.json();
        //   const fdata = data.data
        //   setOfficeBearer(fdata?.map((i) => i));
        // } catch (error) {
        //   console.error("Error fetching office bearers:", error);
        // }
          const data = await response.json();
          const fdata =data.data
          setDownloadMaterialData(fdata?.map((i) => i));
        } catch (error) {
          console.error("Error fetching download data details:", error);
        }
      };
    
      useEffect(() => {
        fetchDownloadmaterialData();
      }, []);

    return(
        <>
         <section className="section">

                <h3 className="display-3 text-start chairmanPageCSS allMainHeading">Download Resources</h3>
            <div className="container-fluid justify-content-center chairmanPageCSS" >
          
                    <DownloadPdf materialData={materialdata}/>
         
            </div>
        </section>
        </>
    );
}
 
export default DownloadResources;