import * as Yup from "yup";

export const articleSchemaFilter = Yup.object().shape({
  // firm_name: Yup.string().required("At least Firm name is required!"),
  // city: Yup.string().required("Firm name is required!"),
  // pincode: Yup.string().min(6, "Pincode must be at least 6 characters").required("Pincode is required"),
});
export const applyJobSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone is required").matches(
    /^[6-9]\d{9}$/,
    "Please enter valid number."
  ),
  // email: Yup.string().required("Email is required").email("Email is invalid"),
});
